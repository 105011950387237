.about .logo {
	background: @about;
}

.about .discover h4 {
	color: @about;
	font-size: 28px;
	letter-spacing: 1px;
}

.about .subheader {
	background: @about;
}

.about li.about-nav a.primary-link {
	color: white;
	background: @about;
}

.about h1 {
	color: @about;
}

.about .box a {
	color: @about;
	border: 1px solid @about;
}

.about .box a:after {
	color: @about;
}