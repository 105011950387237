@import "options";
@import "base";
@import "navigation";

hr {
	border: none;
	background: none;
	border-bottom: 1px solid @light;
}

.lead {
	margin-bottom: 20px;
}

.slider, .hero {
	position: relative;
	clear: both;
	margin: auto;
	max-width: 100%;
	height: 60vh;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	max-height: 569px;
	overflow: hidden;
	text-align: center;
}

.preload {
	opacity: 0;
	filter: blur(2px);
}

.slider img, .hero img {
	width: 100%;
	opacity: 1;
	.transition;
}

.slider img[data-src], .hero img[data-src]  {
  opacity: 0;
}

.hero-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.52) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.52) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.52) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#85000000',GradientType=0 );
}

.hero-text {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding-bottom: 60px;
	color: white;
	text-align: left;
	font-size: 23px;
	span {
		display: block;
		.cond;
		.caps;
		font-size: 18px;
	}
}

.home-intro {
	padding: 30px 0;
}

.home-intro h1 {
	font-size: 24px;
}

.home-intro .subhead {
	color: @dark;
}

.belt {
	padding: 50px 0;
}

.belt-title {
	padding-bottom: 20px;
}

.sections {
	position: relative;
	background: white;
	padding: 40px 0;
	.shadow;
}

.section-list {
	position: relative;
	width: 15%;
	z-index: 2;
}

.section-list li {
	position: relative;
	height: 40px;
}

.section-list li a {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: right;
	color: @dark;
	.roman;
	.caps;
	font-size: 14px;
	line-height: 37px;
	letter-spacing: 1px;
	padding-right: 20px;
	.transition;
}

.section-list li a:hover:before, .section-list li.active a:before  {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	content: '';
	.gradient-fade;
}

.section-list li:hover a, .section-list li.active a {
	color: white;
	right: -70px;
}

.section-list li.about.active a, .section-list li.about:hover a {
	background: @about;
}

.section-list li.go.active a, .section-list li.go:hover a {
	background: @go;
}

.section-list li.do.active a, .section-list li.do:hover a {
	background: @do;
}

.section-list li.stay.active a, .section-list li.stay:hover a {
	background: @stay;
}

.section-list li.press.active a, .section-list li.press:hover a {
	background: @press;
}

.section-list li.eat.active a, .section-list li.eat:hover a {
	background: @eat;
}

.section-list li.plan.active a, .section-list li.plan:hover a {
	background: @plan;
}

.section-list li.gnp.active a, .section-list li.gnp:hover a {
	background: @dark;
}

.section-image {
	display: none;
	position: absolute;
	top: 0;
	left: 15%;
	bottom: 0;
	width: 35%;
	height: 100%;
}

.about-image {
	background: url('/assets/img/section/about.jpg');
	background-size: cover;
	background-position: center center;
}

.go-image {
	background: url('/assets/img/section/go.jpg');
	background-size: cover;
	background-position: center center;
}

.do-image {
	background: url('/assets/img/section/do.jpg');
	background-size: cover;
	background-position: center center;
}

.stay-image {
	background: url('/assets/img/section/stay.jpg');
	background-size: cover;
	background-position: center center;
}

.eat-image {
	background: url('/assets/img/section/eat.jpg');
	background-size: cover;
	background-position: center center;
}

.plan-image {
	background: url('/assets/img/section/plan.jpg');
	background-size: cover;
	background-position: center center;
}

.section-content {
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 50%;
	padding: 35px;
}

.section-content.display, .section-image.display {
	display: block;
}

.about-content {
	background: @about;
}

.go-content {
	background: @go;
}

.do-content {
	background: @do;
}

.stay-content {
	background: @stay;
}

.press-content {
	background: @press;
}

.eat-content {
	background: @eat;
}

.plan-content {
	background: @plan;
}

.section-content h2 {
	.roman;
	font-size: 18px;
	color: white;
	padding-bottom: 30px;
}

.section-content p {
	.main-font;
	font-size: 16px;
	color: white;
}

.section-content a {
	display: block;
	position: absolute;
	bottom: 0px;
	right: 20px;
	color: white;
}

.section-content a i {
	font-size: 40px;
}

.home-logo{
	position: absolute;
	margin: auto;
	display: flex;
	max-width: 600px;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	opacity: 0;
	.transition-slow;
	img {
		width:100%;
	}
}
.homeboxes {
	padding: 30px 0;
}

.homeboxes li.box {
	position: relative;
	.text.red {
		color: @red;
	}
	.text.forest{
		color: @forest;
	}
	.text.orange{
		color: @orange;
	}
	.text.dblue{
		color: @dblue;
	}
	.text.teal{
		color: @teal;
	}
}



li.box a {
	height: 200px;
	overflow: hidden;
}

.box a.forest {
	color: @forest;
	border: 1px solid @forest;
}

.box a.teal {
	color: @teal;
	border: 1px solid @teal;
}

.box a.dblue {
	color: @dblue;
	border: 1px solid @dblue;
}

.box a.red {
	color: @red;
	border: 1px solid @red;
}

.box a.orange {
	color: @orange;
	border: 1px solid @orange;
}

.box a:after {
	position: absolute;
	right: 25px;
	bottom: 15px;
	font-size: 40px;
	.icon;
	content: "\f3d6";
	.transition;
}

.text.forest:after {
	color: @forest;
}

.text.teal:after {
	color: @teal;
}

.text.dblue:after {
	color: @dblue;
}

.text.red:after {
	color: @red;
}

.text.orange:after {
	color: @orange;
}

.halfbox {
	display: block;
	position: relative;
	padding: 15px;
	border: 1px solid @lblue;
	font-size: 24px;
	color: @dark;
	.transition;
	margin-bottom: 28px;
}

.halfbox:hover {
	background: white;
	border: 1px solid white;
	.shadow;
}


.halfbox span, .call span {
	display: block;
	color: @lblue;
	font-size: 12px;
	letter-spacing: .5px;
	.bold;
	.caps;
}

.halfbox:after {
	position: absolute;
	.flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	right: 10px;
	top: 0;
	bottom: 0;
	.icon;
	content: "\f3d6";
	font-size: 35px;
	color: @lblue;
	.transition;
}

.halfbox:hover:after {
	right: 0;
}

.page-box{
	position: relative;
	width: 100%;
	border: 2px solid @dblue;
	cursor: pointer;
	margin-bottom:50px;
	img {
		margin-top:25px;
		object-fit: cover;
		object-position: center;
		width:100%;
		height:300px;
	}
	p {
		padding:20px;
		color:#555;
	}
	h2 {
		text-align: center;
		position: absolute;
		top:0px;
		left:0px;
		right:0px;
		margin-left: auto;
		margin-right: auto;
		background-color: rgba(2,131,177,1);
		color:#fff;
		.transition;
	}
	p:after {
		position: absolute;
		.flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		right: 15px;
		bottom: 0;
		.icon;
		content: "\f3d6";
		font-size: 35px;
		color: @dblue;
		.transition;
	}
}

.page-box:hover{
	border-color:@lblue;
	h2{
		background-color: @lblue;
	}
	p:after{
		right:5px;
	}
}

.premium {
	background: white;
	padding: 20px;
	border: 1px solid @light;
	margin: 30px auto;
	.transition;

	p, span {
		color: @dark;
	}
}

.premium:hover {
	.shadow;
}

.premium-content {
	padding: 20px;
}

.premium-content h4 {
	.roman;
	font-size: 18px;
}

.pemium-logo {
	position: relative;
	.flex;
	height: 171px;
	text-align: center;
	.light-bg;
	border: 1px solid @light;
	padding-bottom: 50px;
}

.premium-cta {
	position: absolute;
	left:0;
	right:0;
	bottom: 0;
	height: 50px;
	background: white;
	text-align: left;
	padding: 12px;
}

.premium-cta-arrow {
	.flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	right:0;
	top: 0;
	bottom: 0;
	background: @red;
	width: 50px;
}

.premium-cta-arrow i {
	font-size: 28px;
	color: white;
}

.pemium-logo img {
	margin: auto;
	max-width: 60%;
}

.belt .premium {
	margin: auto;
}


.update {
	padding: 12px 0;
	border-top: 1px solid @light;
	border-bottom: 1px solid @light;
}

.update .row {
	.flex;
}

.update .row .columns {
	margin: auto;
}

.update p, .update a, .update span {
	font-size: 14px;
}

.update-title {
	display: inline-block;
	color: @lblue;
	.caps;
	padding-right: 30px;
}

.ghost {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid @lblue;
	text-align: center;
	.caps;
	.transition;
	padding: 5px 0;

	i {
		font-size: 21px;
		margin-right: 8px;
	}
}

.ghost:hover {
	background: @lblue;
	color: white;
}

.about {
	a, .ghost, .update-title, .call span, .halfbox span, .halfbox:after {color: @about;}
	.ghost, .halfbox { border: 1px solid @about;}
	.ghost:hover {background: @about;}
}

.go {
	a, .ghost, .update-title, .call span, .halfbox span, .halfbox:after {color: @go;}
	.ghost, .halfbox { border: 1px solid @go;}
	.ghost:hover {background: @go;}
}

.do {
	a, .ghost, .update-title, .call span, .halfbox span, .halfbox:after {color: @do;}
	.ghost, .halfbox { border: 1px solid @do;}
	.ghost:hover {background: @do;}
}

.stay {
	a, .ghost, .update-title, .call span, .halfbox span, .halfbox:after {color: @stay;}
	.ghost, .halfbox { border: 1px solid @stay;}
	.ghost:hover {background: @stay;}
}

.press {
	a, .ghost, .update-title, .call span, .halfbox span, .halfbox:after {color: @press;}
	.ghost, .halfbox { border: 1px solid @press;}
	.ghost:hover {background: @press;}
}

.eat {
	a, .ghost, .update-title, .call span, .halfbox span, .halfbox:after {color: @eat;}
	.ghost, .halfbox { border: 1px solid @eat;}
	.ghost:hover {background: @eat;}
}

.plan {
	a, .update-title, .call span, .halfbox span, .halfbox:after {color: @plan;}
	.ghost, .halfbox { border: 1px solid @plan;}
	.ghost:hover {background: @plan;}
}

.about, .go, .do, .stay, .eat, .plan,.press {
	.halfbox {color: @dark;}
	.ghost:hover {color: white;}
}

.glacier-park {
	a, .ghost, .update-title, .call span, .halfbox span, .halfbox:after {color: @glacier-park;}
	.ghost, .halfbox { border: 1px solid @glacier-park;}
	.ghost:hover {background: @glacier-park;}
}

.post {
	border-bottom: 1px solid @dark;
}

.post-image {
	background: #ccc;
	background-position: cover;
	height: 200px;
}

.post-title {
	font-size: 15px;
}

.post-date p, .post-link p {
	font-size: 11px;
}

.post-link p {
	text-align: right;
}

.communities, .twitter {
	position: relative;
	border-bottom: 1px solid @dark;
	padding-bottom: 10px;
}

.communities:after {
	position: absolute;
	right: 0;
	top: 8px;
	bottom: 0;
	.icon;
	content: "\f3d0";
	font-size: 30px;
	color: @dark;
}
.communities ul {
	position: absolute;
	height: 0px;
	width:100%;
	text-align: left;
	transition: linear .2s all;
	overflow: hidden;
	margin-bottom: 0px;

		input {
			width: 100%;
			padding: 10px;
			background-color: @light;
			margin: 0px;
			border: none;
		}

		li {
			background: @light;
			width:99%;
			padding: 10px 12px;
			margin-bottom: 7px;
			border: 1px solid white;
			.transition;
		}

		li:hover {
			background-color: white;
		}
}
.communities ul.internal-communities{
	background-color:#fff!important;
	border:1px solid #333;
	input {
		background-color: #fff;
	}
	a {
		display: inherit;
	}
	li {
		background: #fff;
		color:#333;
		width:100%;
		padding: 2px 12px;
		margin-bottom: 2px;
		border: none;
	}

	li:hover {
		background-color: @light;
	}
}

.communities:hover ul{
	height: 250px;
	background-color: #e2e3e4;
	z-index: 999;
	width: 100%;
	overflow: scroll;
}

.twitter h4 {
	padding: 15px 0;
}

.twitter p {
	font-size: 12px;
}

.call {
	padding-top: 8px;
}

.call span {
	padding: 0;
	margin: 0;
}

.call p {
	margin: 0;
	padding: 0;
	font-size: 24px;
	color: @dark;
}

.footer-ad {
	margin-top: 15px;
}

footer {
	padding: 30px 0 100px 0;
}

.footer-contact p {
	padding-top: 10px;
	line-height: 22px;
}

.footer-contact img {
	max-width: 55%;
}

.footer-contact li {
	display: inline-block;
	margin-right: 2px;
}

.ad {
	margin-top: 25px;
}

.turbolinks-progress-bar {
  height: 2px;
  background-color: @lblue;
}

.notification {
	display: none;
	position: fixed;
	top: 20px;
	right: 20px;
	width: 20%;
	min-width: 200px;
	padding: 20px 60px 20px 20px;
	background: white;
	.shadow;
	z-index: 99999999;

	.ignore {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: @dark;
		.transition;

		i {
			color: white;
			font-size: 30px;
		}
	}
}
footer li a {color:#555555;}

.notification:hover .ignore {
	background: lighten(@dark, 20%)
}

.expander.btn{
	text-align:center;
	background-color:#fff;
	cursor:pointer;
	.transition;
}

.northern-tier,.southern-tier{
	.content {
		height:0px;
		overflow:hidden;
		.transition;
	}
}
.expandable{
	height:0px;
	overflow:hidden;
}

.expandable.expand{
	height:auto;
	padding-top:15px;
	.transition;
}

.northern-tier.expand .content,.southern-tier.expand .content{
	height:auto;
	padding-top:15px;
}

.i4ewOd-pzNkMb-haAclf {
	background-color: @dblue;
}

.i4ewOd-pzNkMb-ornU0b-b0t70b-Bz112c{
	display:none;
}

.img_content {
	width: 60%;
	display:block;
	margin:auto;
}

.list {
	list-style-type: disc;
	padding-left:18px;
}

.quicksearch {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background: white;
	height: 90px;
	z-index: 9;

	.search-input {
		height: 90px;
		width: 80%;
	}

	.search-button {
		width: 20%;
		height: 90px;

		span {
			margin: auto;
		}

		i {
			.transition;
			cursor: pointer;
		}

		i:hover {
			color: @tblue;
		}
	}

	input {
		.light-font;
		font-size: 35px;
		border: none;
		border-bottom: 1px solid transparent;
		.transition;
		margin-bottom: 0;
	}

	input:focus {
		font-size: 35px;
		border: none;
		border-bottom: 1px solid transparent;
		// border-bottom: 1px solid @dark;
	}

	i {
		font-size: 40px;
	}

	.search-close {
		position: absolute;
		display: flex;
		top: 0;
		right: 0;
		bottom: 5px;
		width: 55px;

		i {
			cursor: pointer;
			margin: auto;
		}
	}
}

.search-toggle i {
	font-size: 21px;
}

.search-wrap, .fixed-header  {
	position: fixed;
	background: white;
	top: 90px;
	left: 0;
	right: 0;
	width: 100%;
	.shadow;
	padding: 20px 0;
	z-index: 8;
}

.search-results-wrapper {
	padding-top: 100px;
}

.big-search {

	input {
		.light-font;
		font-size: 35px;
		border: none;
		border-bottom: 1px solid transparent;
		margin-bottom: 0;
		.transition;
	}

	input:focus {
		font-size: 35px;
		border: none;
		border-bottom: 1px solid transparent;
	}

	i {
		cursor: pointer;
		font-size: 40px;
		.transition;
	}

	i:hover {
		color: @tblue;
	}
}





.search {cursor: pointer;}

.search-results {
	padding: 50px 0;

	h2 {
		.cond;
		.caps;
		font-size: 16px;
		padding-bottom: 20px;
	}
}

.result {
	display: block;
	border: 1px solid @light;
	padding: 20px;
	margin-bottom: 25px;
	min-height: 208px;

	h4 {
		color: #0283b1;
	}

	p {
		color: @dark;
	}
}

.result:hover {
	.shadow;
}



.deal, .trip-item {
	display: block;
	border: 1px solid @light;
	padding: 20px;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 25px;
	.transition;
}

.trip-item:hover {
	.shadow;
}

.valid {
	.cond;
	.caps;
	color: @tblue;
}

.deal:hover {
	.shadow;
}

.photo-credit {
	display: inline-flex;
	position:absolute;
	bottom:0;
	left: 0;
	align-items: center;
	justify-content: center;
	padding:8px;
	background-color: rgba(0,0,0,.7);
	.cond;
	.caps;
	color: white;

	i {
		font-size: 24px;
		padding-right: 8px;
	}
}

.accentbox {
	background: @light;
	padding: 20px;
	margin: 30px 0;
}

@import "microsite";
@import "section";
@import "about";
@import "go";
@import "do";
@import "stay";
@import "press";
@import "eat";
@import "plan";
@import "listings";
@import "trips";
@import "forms";
@import "animations";

.page-title-small {
	padding: 180px 0 60px 0;
	margin-bottom: 50px;

	h1 {
		color: white;
	}
}

footer a {
	color: @dark !important;
}

#blog-class {
  padding-top: 20px;
}

@media (min-width: 1224px) {
	@import "desktop";
}

@media (max-width: 1224px) {
	@import "mobile";
}

.cta-header {
	text-transform: uppercase;
	font-family: HelveticaNeueW01-65Medi;
	font-size: 12px;
	color: @press;
}