.theme {background-color:@red; height:250px;
		p,h2,h3 {color:#fff;}

}

.carousel {
	margin-bottom: 30px;
	height:250px;
	display: flex;
	align-items: center;
	.transition;
}

.carousel:hover {
	.shadow;
}
.carousel-tab {
	padding: 20px;
	p {margin-top: 52px;margin-bottom:0px; text-transform: uppercase;}
}
.carousel-content {
	padding: 20px;
	h3 {
		.roman;
		font-size: 18px;
		color:@red;
		text-transform: uppercase;
	}
	p {
		color:@dark;
	}
}

.carousel-row{
	position:relative; height:250px; background-color: #fff;
}
.contact-shot {
	width:80%;
	border: solid #f2f2f2 3px;
}
.card {
	position: absolute;
	pointer-events: none;
	width:100%;
	.premium {border:none; padding:40px;}
	.carousel-tab {opacity: 0;}
	.carousel-content {opacity: 0;transition: linear .45s all;}
}
.card.current  {
	pointer-events: all;
	.carousel-content {opacity: 1;transition: linear .45s all;}
	.carousel-tab {opacity: 1;}
}
.carousel-select {
	border-top: 1px solid @light;
	position: relative;
  bottom: 30px;
  margin-left: 70%;
	text-align: right;
	.ion-chevron-right:before{
		padding-left:10px;
	}
	a {cursor: pointer;}
	ul {
		display: inline-flex;
		li {
			padding-left: 8px;
			padding-right: 8px;
			color:darken(@light,30%);
			cursor: pointer;
		}
		li.current{
			color:@red;
		}
	}
}
.slider {
	position: relative;
}
.photo-credit {
    display: inline-flex;
    position: absolute;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.7);
    font-family: 'HelveticaNeueW01-57Cn';
    text-transform: uppercase;
    color: white;
		i {
			font-size: 24px;
    	padding-right: 8px;
		}
}

html {
    position: relative;
    border-top: 40px solid #f2f2f2;
    background: white;
}

body {
		position: initial;
    background: white;
    box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}

.second-nav {
    position: absolute;
    top: -35px;
    right: 90px;
    display: flex;
    align-items: center;
    justify-content: right;
		li {
			display: inline-block;
    	margin: auto 15px;
		}
		a {
			color: #333 !important;
			text-transform: uppercase;
			font-family: 'HelveticaNeueW01-57Cn';
			font-size: 12px;
			transition: .2s linear all;
		}
}

@media (max-width: 779px) {
	.carousel-content {
		padding: 20px!important;
	}
	.carousel-tab {
		padding: 20px!important;
		p {margin-top: 0px;}
	}
	.carousel {
		display:block;
		height:380px;
	}
	.theme {
		height:auto;
	}
	.carousel-select{
		margin-left: 0px;
	}
	.carousel-row{
		height:380px;
	}
}
@media (max-width: 500px) {
	.carousel,.carousel-row {
		height:530px;
	}
}
@media (max-width: 350px) {
	.carousel,.carousel-row {
		height:630px;
	}
}
.centering {
	display: block;
	margin:auto;
	img {
		display: block;
		margin:auto;
	}
}
.listing {
	padding-top:10px;
	padding-bottom:10px;
	.image {
		background-size:contain;
		background-position: center;
		height: 220px;
	}
	a h2 {color:@dblue}
	.listing-bar{
		height: 50px;
		border-top: 1px solid @light;
		border-bottom: 1px solid @light;
		a {
			text-transform: uppercase;
			font-size: .8em;
			line-height: 50px;
			padding: 10px;
			i {color:#333; padding-right: 10px; font-size: 1.5em;}
		}
		a.trip-link {
				position: relative;
				background-color: @red;
				color:#fff;
				float:right;
				height:48px;
				line-height: 48px;
				padding: 0px 20px 0px 20px;
				transition: linear .2s all;
		}
		a.trip-link:hover{
			margin-right: 3px;
			background-color:@dblue;
			.ion-ios-plus-empty {

			}
		}
		.ion-ios-plus-empty {
				position: absolute;
				padding: 0px 20px 0px 20px;
				margin-left: 20px;
				color:#fff;
				font-size:2em;
				background-color: @dblue;
		}
	}
}
.listing-search {
	input {margin-top:10px;}
	#CityInput {
    display: block;
    width: 50%;
    margin-bottom: 25px;
    padding: 8px 5px;
    border: 1px solid #e2e3e4;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: .2s linear all;
		border-radius:0px;
		background-color:#fff;}
}
