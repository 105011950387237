@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=70dce019-2449-49af-854f-51dd1d0e9240");
    @font-face{
        font-family:"HelveticaNeueW01-UltLt";
        src:url("/assets/fonts/glacier/Fonts/41774233-b9da-44be-b252-6a7b612fb1c7.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/41774233-b9da-44be-b252-6a7b612fb1c7.eot?#iefix") format("eot"),url("Fonts/9baef9a5-e2af-4838-a3bc-da9d36c0bde8.woff2") format("woff2"),url("Fonts/4bff1fbb-b4bf-4d95-9c47-efcb14384e36.woff") format("woff"),url("Fonts/7f1f2a7d-3837-4c93-b373-f03c5da3f9a1.ttf") format("truetype"),url("Fonts/d9f2752a-8d82-4cf1-b82f-109c1105be7f.svg#d9f2752a-8d82-4cf1-b82f-109c1105be7f") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-Thin";
        src:url("/assets/fonts/glacier/Fonts/56be84de-9d60-4089-8df0-0ea6ec786b84.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/56be84de-9d60-4089-8df0-0ea6ec786b84.eot?#iefix") format("eot"),url("Fonts/aff68211-86bb-476d-882e-f7a3face144c.woff2") format("woff2"),url("Fonts/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff") format("woff"),url("Fonts/278bef59-6be1-4800-b5ac-1f769ab47430.ttf") format("truetype"),url("Fonts/2e309b1b-08b8-477f-bc9e-7067cf0af0b3.svg#2e309b1b-08b8-477f-bc9e-7067cf0af0b3") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-45Ligh";
        src:url("/assets/fonts/glacier/Fonts/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix") format("eot"),url("Fonts/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2") format("woff2"),url("Fonts/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff") format("woff"),url("Fonts/688ab72b-4deb-4e15-a088-89166978d469.ttf") format("truetype"),url("Fonts/7816f72f-f47e-4715-8cd7-960e3723846a.svg#7816f72f-f47e-4715-8cd7-960e3723846a") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-55Roma";
        src:url("/assets/fonts/glacier/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("eot"),url("Fonts/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2") format("woff2"),url("Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff"),url("Fonts/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf") format("truetype"),url("Fonts/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-65Medi";
        src:url("/assets/fonts/glacier/Fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix") format("eot"),url("Fonts/3600b37f-2bf1-45f3-be3a-03365f16d9cb.woff2") format("woff2"),url("Fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff") format("woff"),url("Fonts/4c6503c9-859b-4d3b-a1d5-2d42e1222415.ttf") format("truetype"),url("Fonts/36c182c6-ef98-4021-9b0d-d63122c2bbf5.svg#36c182c6-ef98-4021-9b0d-d63122c2bbf5") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-ThinCn_673383";
        src:url("/assets/fonts/glacier/Fonts/08e2b89e-9853-40e2-b409-05538c48390b.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/08e2b89e-9853-40e2-b409-05538c48390b.eot?#iefix") format("eot"),url("Fonts/07c0e1c6-6a3d-4c99-8311-b77b3223bbaa.woff2") format("woff2"),url("Fonts/3d0bfb88-4887-4fd3-a16f-34ae15cbfe25.woff") format("woff"),url("Fonts/007ff3cd-c6c5-4d33-8458-99c3d6fc8598.ttf") format("truetype"),url("Fonts/67ccdd14-5fcd-479c-9829-ec39a60795fa.svg#67ccdd14-5fcd-479c-9829-ec39a60795fa") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-47LtCn_692686";
        src:url("/assets/fonts/glacier/Fonts/7de6b03f-1298-4bc2-89af-9d9da594008d.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/7de6b03f-1298-4bc2-89af-9d9da594008d.eot?#iefix") format("eot"),url("Fonts/b37ddbd9-24db-4864-9095-5084a5679dd2.woff2") format("woff2"),url("Fonts/42aacb2f-222e-46f5-a95a-1c22948ee0d1.woff") format("woff"),url("Fonts/217a2f81-1e65-4924-a0d4-05ac8576f883.ttf") format("truetype"),url("Fonts/5405647d-099e-47f7-9d4a-f8692e57a206.svg#5405647d-099e-47f7-9d4a-f8692e57a206") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-57Cn";
        src:url("/assets/fonts/glacier/Fonts/ecaa98fe-425a-42de-846b-c235f0dbc449.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/ecaa98fe-425a-42de-846b-c235f0dbc449.eot?#iefix") format("eot"),url("Fonts/88a238be-6477-4e71-bdc4-5a35bbf8d392.woff2") format("woff2"),url("Fonts/842088d0-1ae2-40d0-8561-3c95761ba7a6.woff") format("woff"),url("Fonts/c74505d5-13e8-47ed-94a8-630aab4da3e6.ttf") format("truetype"),url("Fonts/6cd480d1-47d0-48b4-86bd-283db2cb9235.svg#6cd480d1-47d0-48b4-86bd-283db2cb9235") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-67MdCn_692710";
        src:url("/assets/fonts/glacier/Fonts/3a605871-b94d-4161-a394-bb2cfc975df7.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/3a605871-b94d-4161-a394-bb2cfc975df7.eot?#iefix") format("eot"),url("Fonts/423db818-6ba7-4789-995f-1f2d7f6018df.woff2") format("woff2"),url("Fonts/aef05e22-e1d4-4e59-bc2e-a71c13c26cca.woff") format("woff"),url("Fonts/b785b1cf-24fa-44c9-8c93-d8e2d6912c47.ttf") format("truetype"),url("Fonts/5ab0c585-fb4b-43d9-abb0-b92f452b1284.svg#5ab0c585-fb4b-43d9-abb0-b92f452b1284") format("svg");
    }
    @font-face{
        font-family:"HelveticaNeueW01-77BdCn_692722";
        src:url("/assets/fonts/glacier/Fonts/83d5bc89-af33-46a9-8fe3-15d87784f50e.eot?#iefix");
        src:url("/assets/fonts/glacier/Fonts/83d5bc89-af33-46a9-8fe3-15d87784f50e.eot?#iefix") format("eot"),url("Fonts/ab23c239-fb66-406e-bce6-f0f260f5a217.woff2") format("woff2"),url("Fonts/102ab74c-0e84-4fe5-a17a-b20fb643591a.woff") format("woff"),url("Fonts/1d146b29-55e2-485b-96aa-5cb628e7e9eb.ttf") format("truetype"),url("Fonts/d90b3358-e1e2-4abb-ba96-356983a54c22.svg#d90b3358-e1e2-4abb-ba96-356983a54c22") format("svg");
    }
// Import font files
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);

/*
font-family:'HelveticaNeueW01-UltLt';
font-family:'HelveticaNeueW01-Thin';
font-family:'HelveticaNeueW01-65Medi';
font-family:'HelveticaNeueW01-45Ligh';
font-family:'HelveticaNeueW01-ThinCn_673383';
font-family:'HelveticaNeueW01-55Roma';
font-family:'HelveticaNeueW01-67MdCn_692710';
font-family:'HelveticaNeueW01-77BdCn_692722';
font-family:'HelveticaNeueW01-47LtCn_692686';
font-family:'HelveticaNeueW01-57Cn';
*/

// Set Variables here:

@teal: #25ada6;
@dteal: #00898d;
@lblue: #18a5dd;
@tblue: #0283b1;
@dblue: #1b6390;
@purple: #2e358f;
@red: #ee3124;
@orange: #e28c25;
@yellow: #f9ed32;
@green: #4a822b;
@forest: #3d582b;
// @light: #e2e3e4;
@light: #ddd;
@dark: #333;

@link: @tblue;
@hover: darken(@tblue, 20%);

// Section Colors
@about: @lblue;
@go: @green;
@do: @tblue;
@stay: @teal;
@press: @red;
@plan: @orange;
@eat: @purple;
@glacier-park: @forest;

// Device Settings
@desktop: "(min-width: 1024px)";
@mobile: "(max-width: 1024px)";


// Set Mixins here:
.main-font {font-family:'HelveticaNeueW01-45Ligh';}
.light-font {font-family:'HelveticaNeueW01-Thin';}
.cond {font-family:'HelveticaNeueW01-57Cn';}
.roman {font-family:'HelveticaNeueW01-55Roma';}
.bold {font-family:'HelveticaNeueW01-65Medi';}
.caps {text-transform: uppercase;}
.icon {font-family: "Ionicons";}

.rowflex {display: flex;}
.rowflex .columns, .rowflex .column {margin: auto;}

.teal {color: @teal;}
.dteal {color: @dteal;}
.lblue {color: @lblue;}
.tblue {color: @tblue;}
.dblue {color: @dblue;}
.purple {color: @purple;}
.red {color: @red;}
.orange {color: @orange;}
.yellow {color: @yellow;}
.green {color: @green;}
.forest {color: @forest;}
.light {color: @light;}
.dark {color: @dark;}

.teal-bg {background: @teal;}
.dteal-bg {background: @dteal;}
.lblue-bg {background: @lblue;}
.tblue-bg {background: @tblue;}
.dblue-bg {background: @dblue;}
.purple-bg {background: @purple;}
.red-bg {background: @red;}
.orange-bg {background: @orange;}
.yellow-bg {background: @yellow;}
.green-bg {background: @green;}
.forest-bg {background: @forest;}
.light-bg {background: @light;}
.dark-bg {background: @dark;}
.white-bg {background: white;}

.color-about {color: @about;}
.color-go {color: @go;}
.color-do {color: @do;}
.color-stay {color: @stay;}
.color-eat {color: @eat;}
.color-plan {color: @plan;}
.color-glacier-park {color: @glacier-park;}
.color-white {color: white;}

.about .section-color {color: @about;}
.go .section-color {color: @go;}
.do .section-color {color: @do;}
.stay .section-color {color: @stay;}
.eat .section-color {color: @eat;}
.plan .section-color {color: @plan;}
.glacier-park .section-color {color: @glacier-park;}

.bg-about {background: @about;}
.bg-go {background: @go;}
.bg-do {background: @do;}
.bg-stay {background: @stay;}
.bg-eat {background: @eat;}
.bg-plan {background: @plan;}
.bg-glacier-park {background: @glacier-park;}
.center, .align-center {text-align: center;}
span.center {display: block;}
.align-left {text-align: left;}
.align-right {text-align: right;}
.transition {transition:.2s linear all;}
.transition-slow {transition:.5s linear all;}
.shadow {box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);}

hr.clearfix { border: none; opacity: 0;}

.hidden {
    display: none;
}

.pointer {
    cursor: pointer;
}

.gradient-fade {
	background: -moz-linear-gradient(left, rgba(0,0,0,0.31) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-linear-gradient(left, rgba(0,0,0,0.31) 0%,rgba(0,0,0,0) 100%);
	background: linear-gradient(to right, rgba(0,0,0,0.31) 0%,rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4f000000', endColorstr='#00000000',GradientType=1 );
}

.sticky, .discover {
    position: sticky;
    top: 175px;
}