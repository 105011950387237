.do .logo {
	background: @do;
}

.do .discover h4 {
	color: @do;
	font-size: 28px;
	letter-spacing: 1px;
}

.do .subheader {
	background: @do;
}

.do li.do-nav a.primary-link  {
	color: white;
	background: @do;
}

.do h1 {
	color: @do;
}

.do .box a {
	color: @do;
	border: 1px solid @do;
}

.do .box a:after {
	color: @do;
}