.go .logo {
	background: @go;
}

.go .discover h4 {
	color: @go;
	font-size: 28px;
	letter-spacing: 1px;
}

.go .subheader {
	background: @go;
}

.go li.go-nav:hover a.primary-link {
	color: white;
	background: @go;
}

.go .intro-header {
	color: @go;
}

.go .home-intro a {
	.bold;
}

.go .box a {
	color: @go;
	border: 1px solid @go;
	.transition;
}

.go .expander.btn{
	border:1px solid @go;
	color:@go;
}
.go .expander.btn.expand{
	background-color:@go;
	color:#fff;
}

.go .box .desc.blue {
	color: @go;
	border: 1px solid @go;
}
.go .box .desc.blue:after {
	color: @go;
}

.go .box a:after {
	color: @go;
	.transition;
}

.go .box a:hover:after {
	color: @go;
}