header, .no-mobile {
	display: none !important;
}

body {
	margin-top:190px;
}

.mobile-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: white;
	height: 90px;
	z-index: 10;
}

.mobile-header .alert {
	margin-top:90px;
	background-color:#f4756d;
	text-align:center;
	padding:20px;
	.update-title {
		display:block;
	}
	a {
		margin: 20px 20px 0px 20px;
	}
}

.mobile-logo {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 90px;
	background: @press;
	text-align: center;
	padding: 10px;

	a i {
		color: white;
		font-size: 30px;
	}
}

.about .mobile-logo {background: @about;}
.go .mobile-logo {background: @go;}
.do .mobile-logo {background: @do;}
.stay .mobile-logo {background: @stay;}
.eat .mobile-logo {background: @eat;}
.plan .mobile-logo {background: @plan;}
.press .mobile-logo {background: @press;}

.mobile-call {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 0;
	top: 0;
	bottom: 0;
	width: 90px;

	a i {
		color: @dark;
		font-size: 30px;
	}

}

.mobile-nav-wrap, .secondary-mobile-nav-wrap {
	display: none;
	position: fixed;
	width: 100%;
	top: 90px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: @light;
	padding-left: 110px;
	z-index: 12;

	ul {
		padding-top: 30px;
	}

	.primary-link {
		color: @dark;
		.caps;
		letter-spacing: 2px;
		font-size: 21px;
		.light-font;
	}
}

.nav-side {
	position: absolute;
	background-color: @press;
	width: 90px;
	top: 0;
	bottom: 0;
	left: 0;
}

.about .mobile-logo, .about-nav-side, .slideout-about .nav-side {background: @about;}
.go .mobile-logo, .go-nav-side, .slideout-go .nav-side {background: @go;}
.do .mobile-logo, .do-nav-side,  .slideout-do .nav-side {background: @do;}
.stay .mobile-logo, .stay-nav-side,  .slideout-stay .nav-side {background: @stay;}
.eat .mobile-logo, .eat-nav-side,  .slideout-eat .nav-side {background: @eat;}
.plan .mobile-logo, .plan-nav-side,  .slideout-plan .nav-side {background: @plan;}
.press .mobile-logo, .press-nav-side,  .slideout-press .nav-side {background: @press;}

.slideout {
	shadow: none;
	a {
	color: @dark;
	}
}

.subheader {
	top: 0;
	left: 90px;
	height: 90px;
	z-index: 11;

	h2 {
		display: none;
	}
}

.section-nav {
	width: 100%;
	p {
		text-align: right;
		margin-right: 20px;
	}
}

.section-nav-dropdown a {
	overflow: scroll;
}

.drop-icon {
	background: rgba(0, 0, 0, 0.2);
}

.slider, .hero {
	height: 25vh;
}

.belt {
	padding: 20px;
}

.full-mobile {
	width: 100% !important;
	padding: 20px;
}

.listing-bar a {
	display: none;
}

.listing-bar a.trip-link, .listing-bar a.add-to-trip {
	display: block;
	width: 100%;
	line-height: auto;
}

.update .row {
	display: block;
	padding: 20px;
	text-align: center;
	.update-title {display: block;}
}

footer, footer ul, footer ul li, .footer-contact, .footer-contact p {text-align: center;}

.box {
	width: 46% !important;
}

.sectionboxes .box .text{
	font-size:19px;
	padding-left: 5px;
}
.page-box h2 {
	font-size: 22px;
}

.rowflex {
	display: block;
	padding: 20px;
}

.trip-wrapper {padding-top:40px;}
.trip-trigger{
	width: 40px;
	i {font-size: 30px;}
}
.trip-topper {height: 40px; padding-left: 20px;}
.trip-info {min-height: 500px;}

.day-nav-wrap {
	position: absolute;
	top: 40px;
	bottom: 0;
	left: 0;
	background: white;
	width: 90px !important;
	z-index: 2;
}

.trip-wrap .four_up li {
	width: 98% !important;
	height: auto !important;
	padding-bottom: 40px;
	background: white;
}

.trip-wrap {
	position: absolute;
	min-height: 100%;
	left: 0;
	right: 0;
	top: 40px;
	background: @light;
	padding: 20px 20px 20px 110px;
}
.home-logo img {
	width:60%;
	margin:auto;
}


.sponsors.tiles > li, .tiles > .tile {
	height: auto;
	width: 100%;
}
.sponsor img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	object-position: center;
}


.callbox {
	margin-bottom: 10px;
	padding-top: 20px;
}

.info-bar .details {
	.align-center,.align-right{
		text-align: left;
	}
}
.callbox-wide{
	span {
		display:block;
		margin-right:55%;
	}
	.callbox-wide-cta{
		right: -40px;
		white-space: nowrap;
	}
}

.callbox-cta {
	top: 60px;
  height: 40px;
	width:100%;
}
@media (max-width: 620px) {.callbox-cta {top: 90px;}}
@media (max-width: 370px) {.callbox-cta {top: 110px;}}


.lower-content{
	padding-left:10px;
}
.state-listings{
	padding-left:10px;
}