header {
	position: relative;
	top: 0px;
	right: 0;
	left: 0;
	background: white;
	height: 90px;
	z-index: 9;
}

.logo {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	left:0;
	top: 0;
	bottom: 0;
	background: @tblue;
	width: 140px;
}

.logo img {
	width: 100px;
}

.nav {
	margin: auto;
	text-align: center;
}

.nav li {
	display: inline-block;
	height: 90px;
	.cond;
	.caps;
}

.nav li a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90px;
	color: @dark;
	padding: 0px 15px;
}

.nav li:hover a {
	color: white;
}

li.about-nav:hover a.primary-link {
	background: @about;
}

li.go-nav:hover a.primary-link  {
	background: @go;
}

li.do-nav:hover a.primary-link  {
	background: @do;
}

li.press-nav:hover a.primary-link  {
	background: @press;
}

li.plan-nav:hover a.primary-link  {
	background: @plan;
}

li.eat-nav:hover a.primary-link  {
	background: @eat;
}

.contact {
	position: absolute;
	display: flex;
	right: 0;
	top: 0;
	bottom: 0;
	width: auto;
}

.contact ul {
	margin: auto;
	padding-right: 20px;
}

.contact ul li {
	display: inline-block;
	padding: 0px 2px;
}

.contact ul li a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90px;
}

.contact ul li.search {
	padding-left: 60px;
}

.dropdown {
	display: none;
	position: absolute;
	width: 100%;
	top: 90px;
	left: 0;
	right: 0;
	padding: 30px 0;
}

.dropdown ul {
	display: block;
	-webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

	li {
		display: block;
		text-align: left;
		height: auto;

		a {
			display: block;
			text-align: left;
			color: white;
			height: auto;
			.main-font;
			text-transform: none;
		}
	}
}

.primary-item:hover .dropdown {
	display: block;
}

.about-nav .dropdown {
	background: fade(@about, 90%);
}

.go-nav .dropdown {
	background: fade(@go, 90%);
}

.do-nav .dropdown {
	background: fade(@do, 90%);
}

.press-nav .dropdown {
	background: fade(@press, 90%);
}

.eat-nav .dropdown {
	background: fade(@eat, 90%);
}

.plan-nav .dropdown {
	background: fade(@plan, 90%);
}

.subnav-wrapper {
	border-left: 1px solid white;
}

.dropdown li a {
	background: transparent;

}

.dropdown p {
	color: white;
	text-transform: none;
	text-align: left;
}

.section-headline {
	.bold;
	font-size: 20px;
}

.dropdown-column {
	display: flex;

	div {
		margin: auto;
	}
}

header .alert {
	background: #ee3124;
	padding: 12px 0;
	border-top: 1px solid #f2f2f2;
	border-bottom: 1px solid #f2f2f2;
	font-size: 14px;
	.update-title {
		display: inline-block;
		color: #18a5dd;
		text-transform: uppercase;
		padding-right: 30px;
	}
	.row .columns {
		margin: auto;
	}
}