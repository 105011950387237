.filter {
	margin-top: 10px;
	border-top: 1px solid @light;
	border-bottom: 1px solid @light;

	input {
		border: none;
		margin-bottom: 0;
	}
}

.filter-icons {

	text-align: right;

	i {
		display: inline-block;
		font-size: 23px;
		padding: 3px 5px 0 5px;
		color: lighten(@dark,50%);
		cursor: pointer;
		.transition;
	}

	i:hover {
		color: @dark;
	}

	.active {
		color: @dark;
	}
}

.centering {
	display: block;
	margin:auto;
	text-align: center;

	img {
		display: block;
		margin:auto;
	}
}
.listing {
	padding-top:10px;
	padding-bottom:10px;

	.image {
		background-size:contain;
		background-position: center;
		height: 220px;
	}

	.listing-bar {
		position: relative;
		height: 50px;
		border-top: 1px solid @light;
		border-bottom: 1px solid @light;

		a {
			text-transform: uppercase;
			font-size: .8em;
			line-height: 50px;
			padding: 10px;

			i {color: @dark; padding-right: 10px; font-size: 1.5em;}
		}

		a.trip-link {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				color: white;
				top: 0;
				bottom: 0;
				right: 0;
				height:48px;
				line-height: 48px;
				padding: 0px 70px 0px 20px;
		}

		.more-icon {
				position: absolute;
				padding: 0px 20px 0px 20px;
				right: 0;
				color: white;
				font-size: 2em;
				background-color: rgba(0, 0, 0, 0.2);
				.transition;
		}

		a.trip-link:hover .more-icon{
			padding: 0px 15px 0px 25px;
		}
	}
}

.listing-contact a {
	color: @dark;
}

.listing-map {
	border: 5px solid @light;
	margin-top: 30px;

	h3 {
		.cond;
		.caps;
		font-size: 15px;
	}
}

.map-listings {
	h3 {
		.cond;
		.caps;
		font-size: 15px;
	}
}

.listing-more {
	padding-top: 10px;
	margin-top: 20px;
	border-top: 1px solid @light;

	strong {
		.bold;
		font-weight: normal;
	}

	h4 {
		color: @tblue;
		.caps;
		.cond;
		font-size: 16px;
		padding-top: 10px;
	}
}

#image-slider {
	position: relative;
	z-index: 3;

	.owl-wrapper-outer {
		border: 5px solid @light;
	}
}

.state-listing {
	border-top: 1px solid @light;
	padding: 15px 0;

	li {
		display: inline-block;
	}

	li.city {
		width: 25%;
	}

	li.phone {
		width: 25%;
	}

	li.website {
		width: 25%;
	}

	li.address {
		width: 25%;
	}
}

.state-listings {
	/* border-bottom: 1px solid @light; */
}

.image-slide {
	height: 256px;
}

.list-listings, .map-listings {
	display: none;
}

.list-listings.active, .map-listings.active {
	display: block;
}

.about {
	a.trip-link:hover, a.trip-link {background-color: @about;}
	.listing-bar a {color: @about;}
}

.go {
	a.trip-link:hover, a.trip-link {background-color: @go;}
	.listing-bar a {color: @go;}
}

.do {
	a.trip-link:hover, a.trip-link {background-color: @do;}
	.listing-bar a {color: @do;}
}

.stay {
	a.trip-link:hover, a.trip-link {background-color: @stay;}
	.listing-bar a {color: @stay;}
}

.press {
	a.trip-link:hover, a.trip-link {background-color: @press;}
	.listing-bar a {color: @press;}
}

.eat {
	a.trip-link:hover, a.trip-link {background-color: @eat;}
	.listing-bar a {color: @eat;}
}

.plan {
	a.trip-link:hover, a.trip-link {background-color: @plan;}
	.listing-bar a {color: @plan;}
}