
.subheader {
	position: fixed;
	top: 90px;
	left: 0;
	right: 0;
	padding: 8px 20%;
	z-index: 5;
	background: @dark;

	h1 {
		.light;
		color: white;
		.caps;
		font-size: 25px;
	}
}

.section-nav {
	position: absolute;
	display: flex;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.2);
	width: 20%;
	cursor: pointer;

	p {
		margin: auto auto auto 25px;
		letter-spacing: 1px;
		color: white;
		padding-right: 100px;
		.caps;
		.cond;
	}

	.drop-icon {
		position: absolute;
		display: flex;
		right: 0;
		top: 0;
		bottom: 0;
		width: 40px;
		color: white;
		background: rgba(0,0,0,.2);

		i {
			margin: auto;
			font-size: 28px;
			.transition;
		}

		/*
		i:before {
			.icon;
			content: "\f3d0";
			font-size: 20px;
			.transition;
		}
		*/
	}


	.drop-icon.active i {
		transform: rotate(45deg);
		/* content: "\f404"; */
	}

	.drop-more, .drop-less {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 40px;
		top: 0;
		bottom: 0;
		padding-right: 10px;
		.cond;
		.caps;
		color: white;
		margin: auto;
		.transition;
	}

	.drop-more.active {

		opacity: 0;
	}

	.drop-less {
		opacity: 0;
	}

	.drop-less.active {
		opacity: 1;
	}
}

.section-nav-dropdown {
	display: none;
	position: absolute;
	right: 0;
	top: 100%;
	width: 20%;
	background: white;
	padding: 20px;
	.shadow;
}

.section-nav-dropdown li {
	margin: 0;
	a {
		color: @dark;
		text-transform: uppercase;
	}
}

.sectionboxes {
	.box a {
		height: 95px;
	}
	//padding-top: 48px;
	.box .text {
		display: block;
		position: relative;
		padding: 15px 40px 60px 15px;
		line-height: 32px;
	}
}

li.box {
	margin-bottom: 20px;
}

li.box a {
	display: block;
	position: relative;
	padding: 10px;
	color: @dark;
	font-size: 20px;
	line-height: 32px;
	border: 1px solid @dark;
}

li.box a:hover {
	background: white;
	border: 1px solid white;
	.shadow;
}

.box a:after {
	position: absolute;
	right: 25px;
	bottom: 15px;
	font-size: 40px;
	font-family: "Ionicons";
	content: "\f3d6";
	color: @dark;
	.transition;
}

.box a:hover:after {
	right: 15px;
}

.discover {
	text-align: right;
}

.discover h4 {
	color: @about;
}

.discover li {
	display: block;
	width: 100%;

	a {
		color: @dark;
	}
}

.carousel-wrap {
	position: relative;
}

.carousel-arrows {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	width: 50px;

	i {
		color: white;
		font-size: 32px;
	}
}

.arrow-next {
	right: 0;
}

.arrow-last {
	left: 0;
}

.lower-content {
	padding-bottom: 40px;

	.discover {
		text-align: left;
	}

}
