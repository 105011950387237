.press .logo {
	background: @press;
}

.press .discover h4 {
	color: @press;
	font-size: 28px;
	letter-spacing: 1px;
}

.press .subheader {
	background: @press;
}

.press li.press-nav:hover a.primary-link {
	color: white;
	background: @press;
}

.press h1 {
	color: @press;
}

.press .box a {
	color: @press;
	border: 1px solid @press;
}

.press .box a:after {
	color: @press;
}