input {
	display: inline;
	width: 100%;
    margin-bottom: 25px;
    padding: 8px 5px;
    border: 1px solid @light;
    -webkit-appearance: none;
    -moz-appearance: none;
    .transition;
    font-size: 1em;

}

.select-wrapper {
    position: relative;
}

select {
    margin-bottom: 25px;
    margin-left: 10px;
    padding: 8px;
    border: 1px solid @light;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    font-size: 14px;
    .cond;
    .caps;
    .transition;
}

.select-wrapper:after {
    position: absolute;
    right: 8px;
    top: 2px;
    bottom: 0;
    .icon;
    content: '\f3d0';
    pointer-events: none;
}

input[type="submit"] {
    cursor: pointer;
    margin-top: 40px;
    font-size: 17px;
    color: white;
    .caps;
    .cond;
    background: @press;
    .transition;
}

input[type="submit"]:hover {
    background: darken(@press, 5%);
}

.guideform {
    select {
        display: block;
        width: 100%;
        margin-left: 0;
        padding: 6.5px;
    }

    .formgroup {
        .caps;
        .cond;
        color: @press;
    }
}

.reset {
    display: inline-block;
    .cond;
    .caps;
    padding-top: 3px;
}

input:focus, select:focus {
    border: 1px solid @dark;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type="checkbox"], input[type="radio"] {
    position: relative;
    display: inline-block;
    width: auto;
    border: none;
    width: 20px;
    height: 20px;
    padding: 0;
    margin:0 10px 0 0;
    .transition;
}

input[type="checkbox"]:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 4px;
    .icon;
    font-size: 20px;
    content: "\f401";
    .transition;
}

input[type="checkbox"]:checked:after {
    .icon;
    content: "\f3ff";
    .transition;
}

input[type="radio"]:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 4px;
    .icon;
    font-size: 20px;
    content: "\f401";
    .transition;
}

input[type="radio"]:checked:after {
    .icon;
    content: "\f400";
    .transition;
}

label, .password-reset {
    .caps;
    .bold;
    font-size: 12px;
}

button {
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    border-bottom: 1px solid white;
    color: @dark;
    .cond;
    .caps;
    background: white;
    padding: 15px 0;
    .transition;
    -webkit-appearance: none;
    -moz-appearance: none;

    i {
        display: inline-block;
        padding-left: 10px;
        font-size: 30px;
        .transition;
    }

}

button:hover {
    border-bottom: 1px solid @dark;

    i {
        padding-left: 15px;
    }
}

a.password-reset {
    margin-left: 30px;
}

.has-error {
    position: relative;
}

.has-error input, .has-error input:focus {
    border: 1px solid @red;
}

.has-error input {
    border: 1px solid lighten(@red, 20%);
}

.has-error input:focus {
    border: 1px solid @red;
}

.help-block {
    display: block;
    position: absolute;
    left: 0;
    bottom: -42px;
    width: 100%;
    color: white;
    padding: 0px 3px;
    font-size: 12px;
    color: @red;
    margin-bottom: 20px;
}

textarea {
    width:100%;
    height:200px;
}

label {
    span {
        color: @light;
    }
}