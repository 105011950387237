@charset "UTF-8";
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=70dce019-2449-49af-854f-51dd1d0e9240");
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);
@font-face {
  font-family: "HelveticaNeueW01-UltLt";
  src: url("/assets/fonts/glacier/Fonts/41774233-b9da-44be-b252-6a7b612fb1c7.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/41774233-b9da-44be-b252-6a7b612fb1c7.eot?#iefix") format("eot"), url("Fonts/9baef9a5-e2af-4838-a3bc-da9d36c0bde8.woff2") format("woff2"), url("Fonts/4bff1fbb-b4bf-4d95-9c47-efcb14384e36.woff") format("woff"), url("Fonts/7f1f2a7d-3837-4c93-b373-f03c5da3f9a1.ttf") format("truetype"), url("Fonts/d9f2752a-8d82-4cf1-b82f-109c1105be7f.svg#d9f2752a-8d82-4cf1-b82f-109c1105be7f") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-Thin";
  src: url("/assets/fonts/glacier/Fonts/56be84de-9d60-4089-8df0-0ea6ec786b84.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/56be84de-9d60-4089-8df0-0ea6ec786b84.eot?#iefix") format("eot"), url("Fonts/aff68211-86bb-476d-882e-f7a3face144c.woff2") format("woff2"), url("Fonts/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff") format("woff"), url("Fonts/278bef59-6be1-4800-b5ac-1f769ab47430.ttf") format("truetype"), url("Fonts/2e309b1b-08b8-477f-bc9e-7067cf0af0b3.svg#2e309b1b-08b8-477f-bc9e-7067cf0af0b3") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-45Ligh";
  src: url("/assets/fonts/glacier/Fonts/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix") format("eot"), url("Fonts/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2") format("woff2"), url("Fonts/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff") format("woff"), url("Fonts/688ab72b-4deb-4e15-a088-89166978d469.ttf") format("truetype"), url("Fonts/7816f72f-f47e-4715-8cd7-960e3723846a.svg#7816f72f-f47e-4715-8cd7-960e3723846a") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-55Roma";
  src: url("/assets/fonts/glacier/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("eot"), url("Fonts/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2") format("woff2"), url("Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff"), url("Fonts/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf") format("truetype"), url("Fonts/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  src: url("/assets/fonts/glacier/Fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix") format("eot"), url("Fonts/3600b37f-2bf1-45f3-be3a-03365f16d9cb.woff2") format("woff2"), url("Fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff") format("woff"), url("Fonts/4c6503c9-859b-4d3b-a1d5-2d42e1222415.ttf") format("truetype"), url("Fonts/36c182c6-ef98-4021-9b0d-d63122c2bbf5.svg#36c182c6-ef98-4021-9b0d-d63122c2bbf5") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-ThinCn_673383";
  src: url("/assets/fonts/glacier/Fonts/08e2b89e-9853-40e2-b409-05538c48390b.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/08e2b89e-9853-40e2-b409-05538c48390b.eot?#iefix") format("eot"), url("Fonts/07c0e1c6-6a3d-4c99-8311-b77b3223bbaa.woff2") format("woff2"), url("Fonts/3d0bfb88-4887-4fd3-a16f-34ae15cbfe25.woff") format("woff"), url("Fonts/007ff3cd-c6c5-4d33-8458-99c3d6fc8598.ttf") format("truetype"), url("Fonts/67ccdd14-5fcd-479c-9829-ec39a60795fa.svg#67ccdd14-5fcd-479c-9829-ec39a60795fa") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-47LtCn_692686";
  src: url("/assets/fonts/glacier/Fonts/7de6b03f-1298-4bc2-89af-9d9da594008d.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/7de6b03f-1298-4bc2-89af-9d9da594008d.eot?#iefix") format("eot"), url("Fonts/b37ddbd9-24db-4864-9095-5084a5679dd2.woff2") format("woff2"), url("Fonts/42aacb2f-222e-46f5-a95a-1c22948ee0d1.woff") format("woff"), url("Fonts/217a2f81-1e65-4924-a0d4-05ac8576f883.ttf") format("truetype"), url("Fonts/5405647d-099e-47f7-9d4a-f8692e57a206.svg#5405647d-099e-47f7-9d4a-f8692e57a206") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-57Cn";
  src: url("/assets/fonts/glacier/Fonts/ecaa98fe-425a-42de-846b-c235f0dbc449.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/ecaa98fe-425a-42de-846b-c235f0dbc449.eot?#iefix") format("eot"), url("Fonts/88a238be-6477-4e71-bdc4-5a35bbf8d392.woff2") format("woff2"), url("Fonts/842088d0-1ae2-40d0-8561-3c95761ba7a6.woff") format("woff"), url("Fonts/c74505d5-13e8-47ed-94a8-630aab4da3e6.ttf") format("truetype"), url("Fonts/6cd480d1-47d0-48b4-86bd-283db2cb9235.svg#6cd480d1-47d0-48b4-86bd-283db2cb9235") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-67MdCn_692710";
  src: url("/assets/fonts/glacier/Fonts/3a605871-b94d-4161-a394-bb2cfc975df7.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/3a605871-b94d-4161-a394-bb2cfc975df7.eot?#iefix") format("eot"), url("Fonts/423db818-6ba7-4789-995f-1f2d7f6018df.woff2") format("woff2"), url("Fonts/aef05e22-e1d4-4e59-bc2e-a71c13c26cca.woff") format("woff"), url("Fonts/b785b1cf-24fa-44c9-8c93-d8e2d6912c47.ttf") format("truetype"), url("Fonts/5ab0c585-fb4b-43d9-abb0-b92f452b1284.svg#5ab0c585-fb4b-43d9-abb0-b92f452b1284") format("svg");
}
@font-face {
  font-family: "HelveticaNeueW01-77BdCn_692722";
  src: url("/assets/fonts/glacier/Fonts/83d5bc89-af33-46a9-8fe3-15d87784f50e.eot?#iefix");
  src: url("/assets/fonts/glacier/Fonts/83d5bc89-af33-46a9-8fe3-15d87784f50e.eot?#iefix") format("eot"), url("Fonts/ab23c239-fb66-406e-bce6-f0f260f5a217.woff2") format("woff2"), url("Fonts/102ab74c-0e84-4fe5-a17a-b20fb643591a.woff") format("woff"), url("Fonts/1d146b29-55e2-485b-96aa-5cb628e7e9eb.ttf") format("truetype"), url("Fonts/d90b3358-e1e2-4abb-ba96-356983a54c22.svg#d90b3358-e1e2-4abb-ba96-356983a54c22") format("svg");
}
/*
font-family:'HelveticaNeueW01-UltLt';
font-family:'HelveticaNeueW01-Thin';
font-family:'HelveticaNeueW01-65Medi';
font-family:'HelveticaNeueW01-45Ligh';
font-family:'HelveticaNeueW01-ThinCn_673383';
font-family:'HelveticaNeueW01-55Roma';
font-family:'HelveticaNeueW01-67MdCn_692710';
font-family:'HelveticaNeueW01-77BdCn_692722';
font-family:'HelveticaNeueW01-47LtCn_692686';
font-family:'HelveticaNeueW01-57Cn';
*/
.main-font {
  font-family: 'HelveticaNeueW01-45Ligh';
}
.light-font {
  font-family: 'HelveticaNeueW01-Thin';
}
.cond {
  font-family: 'HelveticaNeueW01-57Cn';
}
.roman {
  font-family: 'HelveticaNeueW01-55Roma';
}
.bold {
  font-family: 'HelveticaNeueW01-65Medi';
}
.caps {
  text-transform: uppercase;
}
.icon {
  font-family: "Ionicons";
}
.rowflex {
  display: flex;
}
.rowflex .columns,
.rowflex .column {
  margin: auto;
}
.teal {
  color: #25ada6;
}
.dteal {
  color: #00898d;
}
.lblue {
  color: #18a5dd;
}
.tblue {
  color: #0283b1;
}
.dblue {
  color: #1b6390;
}
.purple {
  color: #2e358f;
}
.red {
  color: #ee3124;
}
.orange {
  color: #e28c25;
}
.yellow {
  color: #f9ed32;
}
.green {
  color: #4a822b;
}
.forest {
  color: #3d582b;
}
.light {
  color: #ddd;
}
.dark {
  color: #333;
}
.teal-bg {
  background: #25ada6;
}
.dteal-bg {
  background: #00898d;
}
.lblue-bg {
  background: #18a5dd;
}
.tblue-bg {
  background: #0283b1;
}
.dblue-bg {
  background: #1b6390;
}
.purple-bg {
  background: #2e358f;
}
.red-bg {
  background: #ee3124;
}
.orange-bg {
  background: #e28c25;
}
.yellow-bg {
  background: #f9ed32;
}
.green-bg {
  background: #4a822b;
}
.forest-bg {
  background: #3d582b;
}
.light-bg {
  background: #ddd;
}
.dark-bg {
  background: #333;
}
.white-bg {
  background: white;
}
.color-about {
  color: #18a5dd;
}
.color-go {
  color: #4a822b;
}
.color-do {
  color: #0283b1;
}
.color-stay {
  color: #25ada6;
}
.color-eat {
  color: #2e358f;
}
.color-plan {
  color: #e28c25;
}
.color-glacier-park {
  color: #3d582b;
}
.color-white {
  color: white;
}
.about .section-color {
  color: #18a5dd;
}
.go .section-color {
  color: #4a822b;
}
.do .section-color {
  color: #0283b1;
}
.stay .section-color {
  color: #25ada6;
}
.eat .section-color {
  color: #2e358f;
}
.plan .section-color {
  color: #e28c25;
}
.glacier-park .section-color {
  color: #3d582b;
}
.bg-about {
  background: #18a5dd;
}
.bg-go {
  background: #4a822b;
}
.bg-do {
  background: #0283b1;
}
.bg-stay {
  background: #25ada6;
}
.bg-eat {
  background: #2e358f;
}
.bg-plan {
  background: #e28c25;
}
.bg-glacier-park {
  background: #3d582b;
}
.center,
.align-center {
  text-align: center;
}
span.center {
  display: block;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.transition {
  transition: .2s linear all;
}
.transition-slow {
  transition: .5s linear all;
}
.shadow {
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
hr.clearfix {
  border: none;
  opacity: 0;
}
.hidden {
  display: none;
}
.pointer {
  cursor: pointer;
}
.gradient-fade {
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4f000000', endColorstr='#00000000', GradientType=1);
}
.sticky,
.discover {
  position: sticky;
  top: 175px;
}
/**
* Windfall Framework
* ---------------
* Built with love by your friends @windfallstudio
* http://www.windfallstudio.com
*/
.main-font {
  font-family: 'HelveticaNeueW01-45Ligh';
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
q,
blockquote {
  quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}
a img {
  border: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
.pull_right {
  float: right;
}
.pull_left {
  float: left;
}
/* Base Styles */
html {
  font-size: 100%;
  line-height: 1.625em;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background: #fff;
  font-family: 'HelveticaNeueW01-45Ligh';
  font-weight: 400;
  color: #555555;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 767px) {
  body {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    width: 100%;
    min-width: 0;
  }
}
html,
body {
  min-height: 100%;
}
.hide {
  display: none;
}
.hide.active,
.show {
  display: block;
}
.fixed {
  position: fixed;
}
.fixed.pinned {
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .fixed {
    position: relative !important;
    top: auto !important;
    left: auto !important;
  }
}
.flex {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .flex {
    display: block;
  }
}
.auto {
  margin: auto;
}
.unfixed {
  position: relative !important;
  top: auto !important;
  left: auto !important;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'HelveticaNeueW01-45Ligh';
  font-weight: 300;
  color: #444444;
  text-rendering: optimizeLegibility;
  padding-top: 0.273em;
  line-height: 1.15538em;
  padding-bottom: 0.273em;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #0283b1;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #01384c;
}
@media only screen and (max-width: 767px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-wrap: break-word;
  }
}
h1 {
  font-size: 30px;
}
h1.xlarge {
  font-size: 110px;
}
h1.xxlarge {
  font-size: 126px;
}
h1.absurd {
  font-size: 177px;
}
h2 {
  font-size: 26px;
}
h3 {
  font-size: 23px;
}
h4 {
  font-size: 21px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 42px;
    font-size: 2.625rem;
  }
  h2 {
    font-size: 36px;
    font-size: 2.25rem;
  }
}
.subhead {
  color: #777;
  font-weight: normal;
  margin-bottom: 20px;
}
/*=====================================================
 Links & Paragraph styles
 ======================================================*/
p {
  font-family: 'HelveticaNeueW01-45Ligh';
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 13px;
  line-height: 1.625em;
}
p.lead {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 18px;
}
@media only screen and (max-width: 768px) {
  p {
    font-size: 17.6px;
    font-size: 1.1rem;
    line-height: 1.625em;
  }
}
a {
  color: #0283b1;
  text-decoration: none;
  outline: 0;
  line-height: inherit;
  transition: .2s linear all;
}
a:hover {
  color: #01384c;
}
/*=====================================================
 Lists
 ======================================================*/
ul,
ol {
  margin-bottom: 0.273em;
}
ul {
  list-style: none outside;
}
ol {
  list-style: decimal;
  margin-left: 30px;
}
ul.square,
ul.circle,
ul.disc {
  margin-left: 25px;
}
ul.square {
  list-style: square outside;
}
ul.circle {
  list-style: circle outside;
}
ul.disc {
  list-style: disc outside;
}
ul ul {
  margin: 4px 0 5px 25px;
}
ol ol {
  margin: 4px 0 5px 30px;
}
li {
  padding-bottom: 0.273em;
}
ul.large li {
  line-height: 21px;
}
dl dt {
  font-weight: bold;
  font-size: 16px;
  font-size: 1rem;
}
@media only screen and (max-width: 768px) {
  ul,
  ol,
  dl,
  p {
    text-align: left;
  }
}
/* Mobile */
em {
  font-style: italic;
  line-height: inherit;
}
strong {
  font-weight: 700;
  line-height: inherit;
}
small {
  font-size: 56.4%;
  line-height: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small {
  color: #777;
}
/*  Blockquotes  */
blockquote {
  line-height: 20px;
  color: #777;
  margin: 0 0 18px;
  padding: 9px 20px 0 19px;
  border-left: 5px solid #ccc;
}
blockquote p {
  line-height: 20px;
  color: #777;
}
blockquote cite {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  color: #555555;
}
blockquote cite:before {
  content: "\2014 \0020";
}
blockquote cite a {
  color: #555555;
}
blockquote cite a:visited {
  color: #555555;
}
hr {
  border: 1px solid #ccc;
  clear: both;
  margin: 16px 0 18px;
  height: 0;
}
abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #222;
  border-bottom: 1px solid #ccc;
  cursor: help;
}
abbr {
  text-transform: none;
}
/** Print styles.  Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/ Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com) */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  /* Black prints faster: sanbeiji.com/archives/953 */
  p a {
    color: #555555 !important;
    text-decoration: underline;
  }
  p a:visited {
    color: #555555 !important;
    text-decoration: underline;
  }
  p a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  /* Don't show links for images, or javascript/internal links */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  /* css-discuss.incutio.com/wiki/Printing_Tables */
  tr,
  img {
    page-break-inside: avoid;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
/*=================================================

	+++ LE GRID +++
	A Responsive Grid -- Windfall defaults to a standard 960 grid,
	but you can change it to whatever you'd like.
 ==================================================*/
/*.container {
	padding: 0 $gutter-in-px;
}*/
.row {
  width: 100%;
  max-width: 1100px;
  min-width: 320px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.row .row,
.full-row {
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
}
/* To fix the grid into a different size, set max-width to your desired width */
.column,
.columns {
  margin-left: 2.12766%;
  float: left;
  min-height: 1px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.column:first-child,
.columns:first-child,
.alpha {
  margin-left: 0;
}
.column.omega,
.columns.omega {
  float: right;
}
/* Column Classes */
.row .one.column {
  width: 6.38298%;
}
.row .one.columns {
  width: 6.38298%;
}
.row .two.columns {
  width: 14.89362%;
}
.row .three.columns {
  width: 23.40426%;
}
.row .four.columns {
  width: 31.91489%;
}
.row .five.columns {
  width: 40.42553%;
}
.row .six.columns {
  width: 48.93617%;
}
.row .seven.columns {
  width: 57.44681%;
}
.row .eight.columns {
  width: 65.95745%;
}
.row .nine.columns {
  width: 74.46809%;
}
.row .ten.columns {
  width: 82.97872%;
}
.row .eleven.columns {
  width: 91.48936%;
}
.row .twelve.columns {
  width: 100%;
}
/* Push and Pull Classes */
.row .push_one {
  margin-left: 10.6383%;
}
.row .push_one:first-child {
  margin-left: 8.51064%;
}
.row .pull_one.one.column {
  margin-left: -14.89362%;
}
.row .pull_one.two.columns {
  margin-left: -23.40426%;
}
.row .pull_one.three.columns {
  margin-left: -31.91489%;
}
.row .pull_one.four.columns {
  margin-left: -40.42553%;
}
.row .pull_one.five.columns {
  margin-left: -48.93617%;
}
.row .pull_one.six.columns {
  margin-left: -57.44681%;
}
.row .pull_one.seven.columns {
  margin-left: -65.95745%;
}
.row .pull_one.eight.columns {
  margin-left: -74.46809%;
}
.row .pull_one.nine.columns {
  margin-left: -82.97872%;
}
.row .pull_one.ten.columns {
  margin-left: -91.48936%;
}
.row .push_two {
  margin-left: 19.14894%;
}
.row .push_two:first-child {
  margin-left: 17.02128%;
}
.row .pull_two.one.column {
  margin-left: -23.40426%;
}
.row .pull_two.two.columns {
  margin-left: -31.91489%;
}
.row .pull_two.three.columns {
  margin-left: -40.42553%;
}
.row .pull_two.four.columns {
  margin-left: -48.93617%;
}
.row .pull_two.five.columns {
  margin-left: -57.44681%;
}
.row .pull_two.six.columns {
  margin-left: -65.95745%;
}
.row .pull_two.seven.columns {
  margin-left: -74.46809%;
}
.row .pull_two.eight.columns {
  margin-left: -82.97872%;
}
.row .pull_two.nine.columns {
  margin-left: -91.48936%;
}
.row .pull_two.eleven.columns {
  margin-left: -108.51064%;
}
.row .push_three {
  margin-left: 27.65957%;
}
.row .push_three:first-child {
  margin-left: 25.53191%;
}
.row .pull_three.one.column {
  margin-left: -31.91489%;
}
.row .pull_three.two.columns {
  margin-left: -40.42553%;
}
.row .pull_three.three.columns {
  margin-left: -48.93617%;
}
.row .pull_three.four.columns {
  margin-left: -57.44681%;
}
.row .pull_three.five.columns {
  margin-left: -65.95745%;
}
.row .pull_three.six.columns {
  margin-left: -74.46809%;
}
.row .pull_three.seven.columns {
  margin-left: -82.97872%;
}
.row .pull_three.eight.columns {
  margin-left: -91.48936%;
}
.row .pull_three.ten.columns {
  margin-left: -108.51064%;
}
.row .pull_three.eleven.columns {
  margin-left: -117.02128%;
}
.row .push_four {
  margin-left: 36.17021%;
}
.row .push_four:first-child {
  margin-left: 34.04255%;
}
.row .pull_four.one.column {
  margin-left: -40.42553%;
}
.row .pull_four.two.columns {
  margin-left: -48.93617%;
}
.row .pull_four.three.columns {
  margin-left: -57.44681%;
}
.row .pull_four.four.columns {
  margin-left: -65.95745%;
}
.row .pull_four.five.columns {
  margin-left: -74.46809%;
}
.row .pull_four.six.columns {
  margin-left: -82.97872%;
}
.row .pull_four.seven.columns {
  margin-left: -91.48936%;
}
.row .pull_four.nine.columns {
  margin-left: -108.51064%;
}
.row .pull_four.ten.columns {
  margin-left: -117.02128%;
}
.row .pull_four.eleven.columns {
  margin-left: -125.53191%;
}
.row .push_five {
  margin-left: 44.68085%;
}
.row .push_five:first-child {
  margin-left: 42.55319%;
}
.row .pull_five.one.column {
  margin-left: -48.93617%;
}
.row .pull_five.two.columns {
  margin-left: -57.44681%;
}
.row .pull_five.three.columns {
  margin-left: -65.95745%;
}
.row .pull_five.four.columns {
  margin-left: -74.46809%;
}
.row .pull_five.five.columns {
  margin-left: -82.97872%;
}
.row .pull_five.six.columns {
  margin-left: -91.48936%;
}
.row .pull_five.eight.columns {
  margin-left: -108.51064%;
}
.row .pull_five.nine.columns {
  margin-left: -117.02128%;
}
.row .pull_five.ten.columns {
  margin-left: -125.53191%;
}
.row .pull_five.eleven.columns {
  margin-left: -134.04255%;
}
.row .push_six {
  margin-left: 53.19149%;
}
.row .push_six:first-child {
  margin-left: 51.06383%;
}
.row .pull_six.one.column {
  margin-left: -57.44681%;
}
.row .pull_six.two.columns {
  margin-left: -65.95745%;
}
.row .pull_six.three.columns {
  margin-left: -74.46809%;
}
.row .pull_six.four.columns {
  margin-left: -82.97872%;
}
.row .pull_six.five.columns {
  margin-left: -91.48936%;
}
.row .pull_six.seven.columns {
  margin-left: -108.51064%;
}
.row .pull_six.eight.columns {
  margin-left: -117.02128%;
}
.row .pull_six.nine.columns {
  margin-left: -125.53191%;
}
.row .pull_six.ten.columns {
  margin-left: -134.04255%;
}
.row .pull_six.eleven.columns {
  margin-left: -142.55319%;
}
.row .push_seven {
  margin-left: 61.70213%;
}
.row .push_seven:first-child {
  margin-left: 59.57447%;
}
.row .pull_seven.one.column {
  margin-left: -65.95745%;
}
.row .pull_seven.two.columns {
  margin-left: -74.46809%;
}
.row .pull_seven.three.columns {
  margin-left: -82.97872%;
}
.row .pull_seven.four.columns {
  margin-left: -91.48936%;
}
.row .pull_seven.six.columns {
  margin-left: -108.51064%;
}
.row .pull_seven.seven.columns {
  margin-left: -117.02128%;
}
.row .pull_seven.eight.columns {
  margin-left: -125.53191%;
}
.row .pull_seven.nine.columns {
  margin-left: -134.04255%;
}
.row .pull_seven.ten.columns {
  margin-left: -142.55319%;
}
.row .pull_seven.eleven.columns {
  margin-left: -151.06383%;
}
.row .push_eight {
  margin-left: 70.21277%;
}
.row .push_eight:first-child {
  margin-left: 68.08511%;
}
.row .pull_eight.one.column {
  margin-left: -74.46809%;
}
.row .pull_eight.two.columns {
  margin-left: -82.97872%;
}
.row .pull_eight.three.columns {
  margin-left: -91.48936%;
}
.row .pull_eight.five.columns {
  margin-left: -108.51064%;
}
.row .pull_eight.six.columns {
  margin-left: -117.02128%;
}
.row .pull_eight.seven.columns {
  margin-left: -125.53191%;
}
.row .pull_eight.eight.columns {
  margin-left: -134.04255%;
}
.row .pull_eight.nine.columns {
  margin-left: -142.55319%;
}
.row .pull_eight.ten.columns {
  margin-left: -151.06383%;
}
.row .pull_eight.eleven.columns {
  margin-left: -159.57447%;
}
.row .push_nine {
  margin-left: 78.7234%;
}
.row .push_nine:first-child {
  margin-left: 76.59574%;
}
.row .pull_nine.one.column {
  margin-left: -82.97872%;
}
.row .pull_nine.two.columns {
  margin-left: -91.48936%;
}
.row .pull_nine.four.columns {
  margin-left: -108.51064%;
}
.row .pull_nine.five.columns {
  margin-left: -117.02128%;
}
.row .pull_nine.six.columns {
  margin-left: -125.53191%;
}
.row .pull_nine.seven.columns {
  margin-left: -134.04255%;
}
.row .pull_nine.eight.columns {
  margin-left: -142.55319%;
}
.row .pull_nine.nine.columns {
  margin-left: -151.06383%;
}
.row .pull_nine.ten.columns {
  margin-left: -159.57447%;
}
.row .pull_nine.eleven.columns {
  margin-left: -168.08511%;
}
.row .push_ten {
  margin-left: 87.23404%;
}
.row .push_ten:first-child {
  margin-left: 85.10638%;
}
.row .pull_ten.one.column {
  margin-left: -91.48936%;
}
.row .pull_ten.three.columns {
  margin-left: -108.51064%;
}
.row .pull_ten.four.columns {
  margin-left: -117.02128%;
}
.row .pull_ten.five.columns {
  margin-left: -125.53191%;
}
.row .pull_ten.six.columns {
  margin-left: -134.04255%;
}
.row .pull_ten.seven.columns {
  margin-left: -142.55319%;
}
.row .pull_ten.eight.columns {
  margin-left: -151.06383%;
}
.row .pull_ten.nine.columns {
  margin-left: -159.57447%;
}
.row .pull_ten.ten.columns {
  margin-left: -168.08511%;
}
.row .pull_ten.eleven.columns {
  margin-left: -176.59574%;
}
.row .push_eleven {
  margin-left: 95.74468%;
}
.row .push_eleven:first-child {
  margin-left: 93.61702%;
}
.row .pull_eleven.two.columns {
  margin-left: -108.51064%;
}
.row .pull_eleven.three.columns {
  margin-left: -117.02128%;
}
.row .pull_eleven.four.columns {
  margin-left: -125.53191%;
}
.row .pull_eleven.five.columns {
  margin-left: -134.04255%;
}
.row .pull_eleven.six.columns {
  margin-left: -142.55319%;
}
.row .pull_eleven.seven.columns {
  margin-left: -151.06383%;
}
.row .pull_eleven.eight.columns {
  margin-left: -159.57447%;
}
.row .pull_eleven.nine.columns {
  margin-left: -168.08511%;
}
.row .pull_eleven.ten.columns {
  margin-left: -176.59574%;
}
.row .pull_eleven.eleven.columns {
  margin-left: -185.10638%;
}
/* Centered Classes */
.row .one.centered {
  margin-left: 46.80851%;
}
.row .two.centered {
  margin-left: 42.55319%;
}
.row .three.centered {
  margin-left: 38.29787%;
}
.row .four.centered {
  margin-left: 34.04255%;
}
.row .five.centered {
  margin-left: 29.78723%;
}
.row .six.centered {
  margin-left: 25.53191%;
}
.row .seven.centered {
  margin-left: 21.2766%;
}
.row .eight.centered {
  margin-left: 17.02128%;
}
.row .nine.centered {
  margin-left: 12.76596%;
}
.row .ten.centered {
  margin-left: 8.51064%;
}
.row .eleven.centered {
  margin-left: 4.25532%;
}
/* Hybrid Grid Columns */
.sixteen.colgrid .row .one.column {
  width: 4.25532%;
}
.sixteen.colgrid .row .one.columns {
  width: 4.25532%;
}
.sixteen.colgrid .row .two.columns {
  width: 10.6383%;
}
.sixteen.colgrid .row .three.columns {
  width: 17.02128%;
}
.sixteen.colgrid .row .four.columns {
  width: 23.40426%;
}
.sixteen.colgrid .row .five.columns {
  width: 29.78723%;
}
.sixteen.colgrid .row .six.columns {
  width: 36.17021%;
}
.sixteen.colgrid .row .seven.columns {
  width: 42.55319%;
}
.sixteen.colgrid .row .eight.columns {
  width: 48.93617%;
}
.sixteen.colgrid .row .nine.columns {
  width: 55.31915%;
}
.sixteen.colgrid .row .ten.columns {
  width: 61.70213%;
}
.sixteen.colgrid .row .eleven.columns {
  width: 68.08511%;
}
.sixteen.colgrid .row .twelve.columns {
  width: 74.46809%;
}
.sixteen.colgrid .row .thirteen.columns {
  width: 80.85106%;
}
.sixteen.colgrid .row .fourteen.columns {
  width: 87.23404%;
}
.sixteen.colgrid .row .fifteen.columns {
  width: 93.61702%;
}
.sixteen.colgrid .row .sixteen.columns {
  width: 100%;
}
/* Hybrid Push and Pull Classes */
.sixteen.colgrid .row .push_one {
  margin-left: 8.51064%;
}
.sixteen.colgrid .row .push_one:first-child {
  margin-left: 6.38298%;
}
.sixteen.colgrid .row .pull_one.one.column {
  margin-left: -10.6383%;
}
.sixteen.colgrid .row .pull_one.two.columns {
  margin-left: -17.02128%;
}
.sixteen.colgrid .row .pull_one.three.columns {
  margin-left: -23.40426%;
}
.sixteen.colgrid .row .pull_one.four.columns {
  margin-left: -29.78723%;
}
.sixteen.colgrid .row .pull_one.five.columns {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_one.six.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_one.seven.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_one.eight.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_one.nine.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_one.ten.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_one.eleven.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_one.twelve.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_one.thirteen.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_one.fourteen.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .push_two {
  margin-left: 14.89362%;
}
.sixteen.colgrid .row .push_two:first-child {
  margin-left: 12.76596%;
}
.sixteen.colgrid .row .pull_two.one.column {
  margin-left: -17.02128%;
}
.sixteen.colgrid .row .pull_two.two.columns {
  margin-left: -23.40426%;
}
.sixteen.colgrid .row .pull_two.three.columns {
  margin-left: -29.78723%;
}
.sixteen.colgrid .row .pull_two.four.columns {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_two.five.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_two.six.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_two.seven.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_two.eight.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_two.nine.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_two.ten.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_two.eleven.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_two.twelve.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_two.thirteen.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_two.fifteen.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .push_three {
  margin-left: 21.2766%;
}
.sixteen.colgrid .row .push_three:first-child {
  margin-left: 19.14894%;
}
.sixteen.colgrid .row .pull_three.one.column {
  margin-left: -23.40426%;
}
.sixteen.colgrid .row .pull_three.two.columns {
  margin-left: -29.78723%;
}
.sixteen.colgrid .row .pull_three.three.columns {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_three.four.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_three.five.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_three.six.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_three.seven.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_three.eight.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_three.nine.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_three.ten.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_three.eleven.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_three.twelve.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_three.fourteen.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_three.fifteen.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .push_four {
  margin-left: 27.65957%;
}
.sixteen.colgrid .row .push_four:first-child {
  margin-left: 25.53191%;
}
.sixteen.colgrid .row .pull_four.one.column {
  margin-left: -29.78723%;
}
.sixteen.colgrid .row .pull_four.two.columns {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_four.three.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_four.four.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_four.five.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_four.six.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_four.seven.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_four.eight.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_four.nine.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_four.ten.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_four.eleven.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_four.thirteen.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_four.fourteen.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_four.fifteen.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .push_five {
  margin-left: 34.04255%;
}
.sixteen.colgrid .row .push_five:first-child {
  margin-left: 31.91489%;
}
.sixteen.colgrid .row .pull_five.one.column {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_five.two.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_five.three.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_five.four.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_five.five.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_five.six.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_five.seven.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_five.eight.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_five.nine.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_five.ten.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_five.twelve.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_five.thirteen.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_five.fourteen.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_five.fifteen.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .push_six {
  margin-left: 40.42553%;
}
.sixteen.colgrid .row .push_six:first-child {
  margin-left: 38.29787%;
}
.sixteen.colgrid .row .pull_six.one.column {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_six.two.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_six.three.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_six.four.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_six.five.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_six.six.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_six.seven.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_six.eight.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_six.nine.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_six.eleven.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_six.twelve.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_six.thirteen.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_six.fourteen.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_six.fifteen.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .push_seven {
  margin-left: 46.80851%;
}
.sixteen.colgrid .row .push_seven:first-child {
  margin-left: 44.68085%;
}
.sixteen.colgrid .row .pull_seven.one.column {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_seven.two.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_seven.three.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_seven.four.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_seven.five.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_seven.six.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_seven.seven.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_seven.eight.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_seven.ten.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_seven.eleven.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_seven.twelve.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_seven.thirteen.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_seven.fourteen.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_seven.fifteen.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .push_eight {
  margin-left: 53.19149%;
}
.sixteen.colgrid .row .push_eight:first-child {
  margin-left: 51.06383%;
}
.sixteen.colgrid .row .pull_eight.one.column {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_eight.two.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_eight.three.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_eight.four.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_eight.five.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_eight.six.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_eight.seven.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_eight.nine.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_eight.ten.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_eight.eleven.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_eight.twelve.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_eight.thirteen.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_eight.fourteen.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_eight.fifteen.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .push_nine {
  margin-left: 59.57447%;
}
.sixteen.colgrid .row .push_nine:first-child {
  margin-left: 57.44681%;
}
.sixteen.colgrid .row .pull_nine.one.column {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_nine.two.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_nine.three.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_nine.four.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_nine.five.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_nine.six.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_nine.eight.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_nine.nine.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_nine.ten.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_nine.eleven.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_nine.twelve.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_nine.thirteen.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_nine.fourteen.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_nine.fifteen.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .push_ten {
  margin-left: 65.95745%;
}
.sixteen.colgrid .row .push_ten:first-child {
  margin-left: 63.82979%;
}
.sixteen.colgrid .row .pull_ten.one.column {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_ten.two.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_ten.three.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_ten.four.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_ten.five.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_ten.seven.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_ten.eight.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_ten.nine.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_ten.ten.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_ten.eleven.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_ten.twelve.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_ten.thirteen.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_ten.fourteen.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_ten.fifteen.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .push_eleven {
  margin-left: 72.34043%;
}
.sixteen.colgrid .row .push_eleven:first-child {
  margin-left: 70.21277%;
}
.sixteen.colgrid .row .pull_eleven.one.column {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_eleven.two.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_eleven.three.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_eleven.four.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_eleven.six.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_eleven.seven.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_eleven.eight.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_eleven.nine.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_eleven.ten.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_eleven.eleven.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_eleven.twelve.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_eleven.thirteen.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_eleven.fourteen.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_eleven.fifteen.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .push_twelve {
  margin-left: 78.7234%;
}
.sixteen.colgrid .row .push_twelve:first-child {
  margin-left: 76.59574%;
}
.sixteen.colgrid .row .pull_twelve.one.column {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_twelve.two.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_twelve.three.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_twelve.five.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_twelve.six.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_twelve.seven.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_twelve.eight.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_twelve.nine.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_twelve.ten.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_twelve.eleven.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_twelve.twelve.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_twelve.thirteen.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_twelve.fourteen.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .pull_twelve.fifteen.columns {
  margin-left: -170.21277%;
}
.sixteen.colgrid .row .push_thirteen {
  margin-left: 85.10638%;
}
.sixteen.colgrid .row .push_thirteen:first-child {
  margin-left: 82.97872%;
}
.sixteen.colgrid .row .pull_thirteen.one.column {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_thirteen.two.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_thirteen.four.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_thirteen.five.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_thirteen.six.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_thirteen.seven.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_thirteen.eight.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_thirteen.nine.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_thirteen.ten.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_thirteen.eleven.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_thirteen.twelve.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_thirteen.thirteen.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .pull_thirteen.fourteen.columns {
  margin-left: -170.21277%;
}
.sixteen.colgrid .row .pull_thirteen.fifteen.columns {
  margin-left: -176.59574%;
}
.sixteen.colgrid .row .push_fourteen {
  margin-left: 91.48936%;
}
.sixteen.colgrid .row .push_fourteen:first-child {
  margin-left: 89.3617%;
}
.sixteen.colgrid .row .pull_fourteen.one.column {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_fourteen.three.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_fourteen.four.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_fourteen.five.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_fourteen.six.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_fourteen.seven.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_fourteen.eight.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_fourteen.nine.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_fourteen.ten.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_fourteen.eleven.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_fourteen.twelve.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .pull_fourteen.thirteen.columns {
  margin-left: -170.21277%;
}
.sixteen.colgrid .row .pull_fourteen.fourteen.columns {
  margin-left: -176.59574%;
}
.sixteen.colgrid .row .pull_fourteen.fifteen.columns {
  margin-left: -182.97872%;
}
.sixteen.colgrid .row .push_fifteen {
  margin-left: 97.87234%;
}
.sixteen.colgrid .row .push_fifteen:first-child {
  margin-left: 95.74468%;
}
.sixteen.colgrid .row .pull_fifteen.two.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_fifteen.three.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_fifteen.four.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_fifteen.five.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_fifteen.six.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_fifteen.seven.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_fifteen.eight.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_fifteen.nine.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_fifteen.ten.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_fifteen.eleven.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .pull_fifteen.twelve.columns {
  margin-left: -170.21277%;
}
.sixteen.colgrid .row .pull_fifteen.thirteen.columns {
  margin-left: -176.59574%;
}
.sixteen.colgrid .row .pull_fifteen.fourteen.columns {
  margin-left: -182.97872%;
}
.sixteen.colgrid .row .pull_fifteen.fifteen.columns {
  margin-left: -189.3617%;
}
.row .pull_one.one.column:first-child,
.row .pull_one.two.columns:first-child,
.row .pull_one.three.columns:first-child,
.row .pull_one.four.columns:first-child,
.row .pull_one.five.columns:first-child,
.row .pull_one.six.columns:first-child,
.row .pull_one.seven.columns:first-child,
.row .pull_one.eight.columns:first-child,
.row .pull_one.nine.columns:first-child,
.row .pull_one.ten.columns:first-child,
.row .pull_two.one.column:first-child,
.row .pull_two.two.columns:first-child,
.row .pull_two.three.columns:first-child,
.row .pull_two.four.columns:first-child,
.row .pull_two.five.columns:first-child,
.row .pull_two.six.columns:first-child,
.row .pull_two.seven.columns:first-child,
.row .pull_two.eight.columns:first-child,
.row .pull_two.nine.columns:first-child,
.row .pull_two.eleven.columns:first-child,
.row .pull_three.one.column:first-child,
.row .pull_three.two.columns:first-child,
.row .pull_three.three.columns:first-child,
.row .pull_three.four.columns:first-child,
.row .pull_three.five.columns:first-child,
.row .pull_three.six.columns:first-child,
.row .pull_three.seven.columns:first-child,
.row .pull_three.eight.columns:first-child,
.row .pull_three.ten.columns:first-child,
.row .pull_three.eleven.columns:first-child,
.row .pull_four.one.column:first-child,
.row .pull_four.two.columns:first-child,
.row .pull_four.three.columns:first-child,
.row .pull_four.four.columns:first-child,
.row .pull_four.five.columns:first-child,
.row .pull_four.six.columns:first-child,
.row .pull_four.seven.columns:first-child,
.row .pull_four.nine.columns:first-child,
.row .pull_four.ten.columns:first-child,
.row .pull_four.eleven.columns:first-child,
.row .pull_five.one.column:first-child,
.row .pull_five.two.columns:first-child,
.row .pull_five.three.columns:first-child,
.row .pull_five.four.columns:first-child,
.row .pull_five.five.columns:first-child,
.row .pull_five.six.columns:first-child,
.row .pull_five.eight.columns:first-child,
.row .pull_five.nine.columns:first-child,
.row .pull_five.ten.columns:first-child,
.row .pull_five.eleven.columns:first-child,
.row .pull_six.one.column:first-child,
.row .pull_six.two.columns:first-child,
.row .pull_six.three.columns:first-child,
.row .pull_six.four.columns:first-child,
.row .pull_six.five.columns:first-child,
.row .pull_six.seven.columns:first-child,
.row .pull_six.eight.columns:first-child,
.row .pull_six.nine.columns:first-child,
.row .pull_six.ten.columns:first-child,
.row .pull_six.eleven.columns:first-child,
.row .pull_seven.one.column:first-child,
.row .pull_seven.two.columns:first-child,
.row .pull_seven.three.columns:first-child,
.row .pull_seven.four.columns:first-child,
.row .pull_seven.six.columns:first-child,
.row .pull_seven.seven.columns:first-child,
.row .pull_seven.eight.columns:first-child,
.row .pull_seven.nine.columns:first-child,
.row .pull_seven.ten.columns:first-child,
.row .pull_seven.eleven.columns:first-child,
.row .pull_eight.one.column:first-child,
.row .pull_eight.two.columns:first-child,
.row .pull_eight.three.columns:first-child,
.row .pull_eight.five.columns:first-child,
.row .pull_eight.six.columns:first-child,
.row .pull_eight.seven.columns:first-child,
.row .pull_eight.eight.columns:first-child,
.row .pull_eight.nine.columns:first-child,
.row .pull_eight.ten.columns:first-child,
.row .pull_eight.eleven.columns:first-child,
.row .pull_nine.one.column:first-child,
.row .pull_nine.two.columns:first-child,
.row .pull_nine.four.columns:first-child,
.row .pull_nine.five.columns:first-child,
.row .pull_nine.six.columns:first-child,
.row .pull_nine.seven.columns:first-child,
.row .pull_nine.eight.columns:first-child,
.row .pull_nine.nine.columns:first-child,
.row .pull_nine.ten.columns:first-child,
.row .pull_nine.eleven.columns:first-child,
.row .pull_ten.one.column:first-child,
.row .pull_ten.three.columns:first-child,
.row .pull_ten.four.columns:first-child,
.row .pull_ten.five.columns:first-child,
.row .pull_ten.six.columns:first-child,
.row .pull_ten.seven.columns:first-child,
.row .pull_ten.eight.columns:first-child,
.row .pull_ten.nine.columns:first-child,
.row .pull_ten.ten.columns:first-child,
.row .pull_ten.eleven.columns:first-child,
.row .pull_eleven.two.columns:first-child,
.row .pull_eleven.three.columns:first-child,
.row .pull_eleven.four.columns:first-child,
.row .pull_eleven.five.columns:first-child,
.row .pull_eleven.six.columns:first-child,
.row .pull_eleven.seven.columns:first-child,
.row .pull_eleven.eight.columns:first-child,
.row .pull_eleven.nine.columns:first-child,
.row .pull_eleven.ten.columns:first-child,
.row .pull_eleven.eleven.columns:first-child,
.sixteen.colgrid .row .pull_one.one.column:first-child,
.sixteen.colgrid .row .pull_one.two.columns:first-child,
.sixteen.colgrid .row .pull_one.three.columns:first-child,
.sixteen.colgrid .row .pull_one.four.columns:first-child,
.sixteen.colgrid .row .pull_one.five.columns:first-child,
.sixteen.colgrid .row .pull_one.six.columns:first-child,
.sixteen.colgrid .row .pull_one.seven.columns:first-child,
.sixteen.colgrid .row .pull_one.eight.columns:first-child,
.sixteen.colgrid .row .pull_one.nine.columns:first-child,
.sixteen.colgrid .row .pull_one.ten.columns:first-child,
.sixteen.colgrid .row .pull_one.eleven.columns:first-child,
.sixteen.colgrid .row .pull_one.twelve.columns:first-child,
.sixteen.colgrid .row .pull_one.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_one.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_two.one.column:first-child,
.sixteen.colgrid .row .pull_two.two.columns:first-child,
.sixteen.colgrid .row .pull_two.three.columns:first-child,
.sixteen.colgrid .row .pull_two.four.columns:first-child,
.sixteen.colgrid .row .pull_two.five.columns:first-child,
.sixteen.colgrid .row .pull_two.six.columns:first-child,
.sixteen.colgrid .row .pull_two.seven.columns:first-child,
.sixteen.colgrid .row .pull_two.eight.columns:first-child,
.sixteen.colgrid .row .pull_two.nine.columns:first-child,
.sixteen.colgrid .row .pull_two.ten.columns:first-child,
.sixteen.colgrid .row .pull_two.eleven.columns:first-child,
.sixteen.colgrid .row .pull_two.twelve.columns:first-child,
.sixteen.colgrid .row .pull_two.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_two.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_three.one.column:first-child,
.sixteen.colgrid .row .pull_three.two.columns:first-child,
.sixteen.colgrid .row .pull_three.three.columns:first-child,
.sixteen.colgrid .row .pull_three.four.columns:first-child,
.sixteen.colgrid .row .pull_three.five.columns:first-child,
.sixteen.colgrid .row .pull_three.six.columns:first-child,
.sixteen.colgrid .row .pull_three.seven.columns:first-child,
.sixteen.colgrid .row .pull_three.eight.columns:first-child,
.sixteen.colgrid .row .pull_three.nine.columns:first-child,
.sixteen.colgrid .row .pull_three.ten.columns:first-child,
.sixteen.colgrid .row .pull_three.eleven.columns:first-child,
.sixteen.colgrid .row .pull_three.twelve.columns:first-child,
.sixteen.colgrid .row .pull_three.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_three.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_four.one.column:first-child,
.sixteen.colgrid .row .pull_four.two.columns:first-child,
.sixteen.colgrid .row .pull_four.three.columns:first-child,
.sixteen.colgrid .row .pull_four.four.columns:first-child,
.sixteen.colgrid .row .pull_four.five.columns:first-child,
.sixteen.colgrid .row .pull_four.six.columns:first-child,
.sixteen.colgrid .row .pull_four.seven.columns:first-child,
.sixteen.colgrid .row .pull_four.eight.columns:first-child,
.sixteen.colgrid .row .pull_four.nine.columns:first-child,
.sixteen.colgrid .row .pull_four.ten.columns:first-child,
.sixteen.colgrid .row .pull_four.eleven.columns:first-child,
.sixteen.colgrid .row .pull_four.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_four.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_four.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_five.one.column:first-child,
.sixteen.colgrid .row .pull_five.two.columns:first-child,
.sixteen.colgrid .row .pull_five.three.columns:first-child,
.sixteen.colgrid .row .pull_five.four.columns:first-child,
.sixteen.colgrid .row .pull_five.five.columns:first-child,
.sixteen.colgrid .row .pull_five.six.columns:first-child,
.sixteen.colgrid .row .pull_five.seven.columns:first-child,
.sixteen.colgrid .row .pull_five.eight.columns:first-child,
.sixteen.colgrid .row .pull_five.nine.columns:first-child,
.sixteen.colgrid .row .pull_five.ten.columns:first-child,
.sixteen.colgrid .row .pull_five.twelve.columns:first-child,
.sixteen.colgrid .row .pull_five.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_five.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_five.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_six.one.column:first-child,
.sixteen.colgrid .row .pull_six.two.columns:first-child,
.sixteen.colgrid .row .pull_six.three.columns:first-child,
.sixteen.colgrid .row .pull_six.four.columns:first-child,
.sixteen.colgrid .row .pull_six.five.columns:first-child,
.sixteen.colgrid .row .pull_six.six.columns:first-child,
.sixteen.colgrid .row .pull_six.seven.columns:first-child,
.sixteen.colgrid .row .pull_six.eight.columns:first-child,
.sixteen.colgrid .row .pull_six.nine.columns:first-child,
.sixteen.colgrid .row .pull_six.eleven.columns:first-child,
.sixteen.colgrid .row .pull_six.twelve.columns:first-child,
.sixteen.colgrid .row .pull_six.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_six.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_six.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_seven.one.column:first-child,
.sixteen.colgrid .row .pull_seven.two.columns:first-child,
.sixteen.colgrid .row .pull_seven.three.columns:first-child,
.sixteen.colgrid .row .pull_seven.four.columns:first-child,
.sixteen.colgrid .row .pull_seven.five.columns:first-child,
.sixteen.colgrid .row .pull_seven.six.columns:first-child,
.sixteen.colgrid .row .pull_seven.seven.columns:first-child,
.sixteen.colgrid .row .pull_seven.eight.columns:first-child,
.sixteen.colgrid .row .pull_seven.ten.columns:first-child,
.sixteen.colgrid .row .pull_seven.eleven.columns:first-child,
.sixteen.colgrid .row .pull_seven.twelve.columns:first-child,
.sixteen.colgrid .row .pull_seven.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_seven.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_seven.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_eight.one.column:first-child,
.sixteen.colgrid .row .pull_eight.two.columns:first-child,
.sixteen.colgrid .row .pull_eight.three.columns:first-child,
.sixteen.colgrid .row .pull_eight.four.columns:first-child,
.sixteen.colgrid .row .pull_eight.five.columns:first-child,
.sixteen.colgrid .row .pull_eight.six.columns:first-child,
.sixteen.colgrid .row .pull_eight.seven.columns:first-child,
.sixteen.colgrid .row .pull_eight.nine.columns:first-child,
.sixteen.colgrid .row .pull_eight.ten.columns:first-child,
.sixteen.colgrid .row .pull_eight.eleven.columns:first-child,
.sixteen.colgrid .row .pull_eight.twelve.columns:first-child,
.sixteen.colgrid .row .pull_eight.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_eight.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_eight.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_nine.one.column:first-child,
.sixteen.colgrid .row .pull_nine.two.columns:first-child,
.sixteen.colgrid .row .pull_nine.three.columns:first-child,
.sixteen.colgrid .row .pull_nine.four.columns:first-child,
.sixteen.colgrid .row .pull_nine.five.columns:first-child,
.sixteen.colgrid .row .pull_nine.six.columns:first-child,
.sixteen.colgrid .row .pull_nine.eight.columns:first-child,
.sixteen.colgrid .row .pull_nine.nine.columns:first-child,
.sixteen.colgrid .row .pull_nine.ten.columns:first-child,
.sixteen.colgrid .row .pull_nine.eleven.columns:first-child,
.sixteen.colgrid .row .pull_nine.twelve.columns:first-child,
.sixteen.colgrid .row .pull_nine.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_nine.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_nine.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_ten.one.column:first-child,
.sixteen.colgrid .row .pull_ten.two.columns:first-child,
.sixteen.colgrid .row .pull_ten.three.columns:first-child,
.sixteen.colgrid .row .pull_ten.four.columns:first-child,
.sixteen.colgrid .row .pull_ten.five.columns:first-child,
.sixteen.colgrid .row .pull_ten.seven.columns:first-child,
.sixteen.colgrid .row .pull_ten.eight.columns:first-child,
.sixteen.colgrid .row .pull_ten.nine.columns:first-child,
.sixteen.colgrid .row .pull_ten.ten.columns:first-child,
.sixteen.colgrid .row .pull_ten.eleven.columns:first-child,
.sixteen.colgrid .row .pull_ten.twelve.columns:first-child,
.sixteen.colgrid .row .pull_ten.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_ten.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_ten.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_eleven.one.column:first-child,
.sixteen.colgrid .row .pull_eleven.two.columns:first-child,
.sixteen.colgrid .row .pull_eleven.three.columns:first-child,
.sixteen.colgrid .row .pull_eleven.four.columns:first-child,
.sixteen.colgrid .row .pull_eleven.six.columns:first-child,
.sixteen.colgrid .row .pull_eleven.seven.columns:first-child,
.sixteen.colgrid .row .pull_eleven.eight.columns:first-child,
.sixteen.colgrid .row .pull_eleven.nine.columns:first-child,
.sixteen.colgrid .row .pull_eleven.ten.columns:first-child,
.sixteen.colgrid .row .pull_eleven.eleven.columns:first-child,
.sixteen.colgrid .row .pull_eleven.twelve.columns:first-child,
.sixteen.colgrid .row .pull_eleven.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_eleven.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_eleven.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_twelve.one.column:first-child,
.sixteen.colgrid .row .pull_twelve.two.columns:first-child,
.sixteen.colgrid .row .pull_twelve.three.columns:first-child,
.sixteen.colgrid .row .pull_twelve.five.columns:first-child,
.sixteen.colgrid .row .pull_twelve.six.columns:first-child,
.sixteen.colgrid .row .pull_twelve.seven.columns:first-child,
.sixteen.colgrid .row .pull_twelve.eight.columns:first-child,
.sixteen.colgrid .row .pull_twelve.nine.columns:first-child,
.sixteen.colgrid .row .pull_twelve.ten.columns:first-child,
.sixteen.colgrid .row .pull_twelve.eleven.columns:first-child,
.sixteen.colgrid .row .pull_twelve.twelve.columns:first-child,
.sixteen.colgrid .row .pull_twelve.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_twelve.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_twelve.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.one.column:first-child,
.sixteen.colgrid .row .pull_thirteen.two.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.four.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.five.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.six.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.seven.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.eight.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.nine.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.ten.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.eleven.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.twelve.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.one.column:first-child,
.sixteen.colgrid .row .pull_fourteen.three.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.four.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.five.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.six.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.seven.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.eight.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.nine.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.ten.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.eleven.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.twelve.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.two.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.three.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.four.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.five.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.six.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.seven.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.eight.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.nine.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.ten.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.eleven.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.twelve.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.fifteen.columns:first-child {
  margin-left: 0;
}
.row .pull_one.eleven.columns,
.row .pull_two.ten.columns,
.row .pull_three.nine.columns,
.row .pull_four.eight.columns,
.row .pull_five.seven.columns,
.row .pull_six.six.columns,
.row .pull_seven.five.columns,
.row .pull_eight.four.columns,
.row .pull_nine.three.columns,
.row .pull_ten.two.columns,
.row .pull_eleven.one.columns,
.sixteen.colgrid .row .pull_one.fifteen.columns,
.sixteen.colgrid .row .pull_two.fourteen.columns,
.sixteen.colgrid .row .pull_three.thirteen.columns,
.sixteen.colgrid .row .pull_four.twelve.columns,
.sixteen.colgrid .row .pull_five.eleven.columns,
.sixteen.colgrid .row .pull_six.ten.columns,
.sixteen.colgrid .row .pull_seven.nine.columns,
.sixteen.colgrid .row .pull_eight.eight.columns,
.sixteen.colgrid .row .pull_nine.seven.columns,
.sixteen.colgrid .row .pull_ten.six.columns,
.sixteen.colgrid .row .pull_eleven.five.columns,
.sixteen.colgrid .row .pull_twelve.four.columns,
.sixteen.colgrid .row .pull_thirteen.three.columns,
.sixteen.colgrid .row .pull_fourteen.two.columns,
.sixteen.colgrid .row .pull_fifteen.one.columns {
  margin-left: -100%;
}
/* Hybrid Centered Classes */
.sixteen.colgrid .row .one.centered {
  margin-left: 47.87234%;
}
.sixteen.colgrid .row .two.centered {
  margin-left: 44.68085%;
}
.sixteen.colgrid .row .three.centered {
  margin-left: 41.48936%;
}
.sixteen.colgrid .row .four.centered {
  margin-left: 38.29787%;
}
.sixteen.colgrid .row .five.centered {
  margin-left: 35.10638%;
}
.sixteen.colgrid .row .six.centered {
  margin-left: 31.91489%;
}
.sixteen.colgrid .row .seven.centered {
  margin-left: 28.7234%;
}
.sixteen.colgrid .row .eight.centered {
  margin-left: 25.53191%;
}
.sixteen.colgrid .row .nine.centered {
  margin-left: 22.34043%;
}
.sixteen.colgrid .row .ten.centered {
  margin-left: 19.14894%;
}
.sixteen.colgrid .row .eleven.centered {
  margin-left: 15.95745%;
}
.sixteen.colgrid .row .twelve.centered {
  margin-left: 12.76596%;
}
.sixteen.colgrid .row .thirteen.centered {
  margin-left: 9.57447%;
}
.sixteen.colgrid .row .fourteen.centered {
  margin-left: 6.38298%;
}
.sixteen.colgrid .row .fifteen.centered {
  margin-left: 3.19149%;
}
img,
object,
embed {
  max-width: 100%;
  height: auto;
}
img {
  -ms-interpolation-mode: bicubic;
}
#map_canvas img,
.map_canvas img {
  max-width: none !important;
}
/* Tile Grid */
.tiles {
  display: block;
  overflow: hidden;
}
.tiles > li,
.tiles > .tile {
  display: block;
  height: auto;
  float: left;
  padding-bottom: 0;
}
.tiles.two_up {
  margin-left: -4%;
}
.tiles.two_up > li,
.tiles.two_up > .tile {
  margin-left: 4%;
  width: 46%;
}
.tiles.three_up,
.tiles.four_up {
  margin-left: -2%;
}
.tiles.three_up > li,
.tiles.three_up > .tile {
  margin-left: 2%;
  width: 31.3%;
}
.tiles.four_up > li,
.tiles.four_up > .tile {
  margin-left: 2%;
  width: 23%;
}
.tiles.five_up {
  margin-left: -1.5%;
}
.tiles.five_up > li,
.tiles.five_up > .tile {
  margin-left: 1.5%;
  width: 18.5%;
}
/* Nicolas Gallagher's micro clearfix */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.row {
  *zoom: 1;
}
.row:before,
.row:after {
  content: "";
  display: table;
}
.row:after {
  clear: both;
}
.valign:before {
  content: ' ';
  display: inline-block;
  height: 400px;
  vertical-align: middle;
  margin-right: -0.25em;
}
.valign > div,
.valign > article,
.valign > section,
.valign > figure {
  display: inline-block;
  vertical-align: middle;
}
/* Mobile */
@media only screen and (max-width: 767px) {
  body {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    width: 100%;
    min-width: 0;
  }
  .container {
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .row {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .row .row .column,
  .row .row .columns {
    padding: 0;
  }
  .row .centered {
    margin-left: 0 !important;
  }
  .column,
  .columns {
    width: auto !important;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .column:last-child,
  .columns:last-child {
    margin-right: 0;
    float: none;
  }
  [class*="column"] + [class*="column"]:last-child {
    float: none;
  }
  [class*="column"]:before {
    display: table;
  }
  [class*="column"]:after {
    display: table;
    clear: both;
  }
  [class^="push_"],
  [class*="push_"],
  [class^="pull_"],
  [class*="pull_"] {
    margin-left: 0 !important;
  }
}
/* SHAME */
.ie8 .xxwide,
.ie8 .xwide,
.ie8 .wide,
.ie8 .normal,
.ie8 .narrow,
.ie8 .xnarrow {
  display: inline;
}
.ie8 .xxwide + input,
.ie8 .xwide + input,
.ie8 .wide + input,
.ie8 .normal + input,
.ie8 .narrow + input,
.ie8 .xnarrow + input {
  display: inline;
  margin: 0 0 0 -0.25em;
}
.ie8 .ttip:before,
.ie8 .ttip:after {
  display: none;
}
.ie8 .ttip:hover:before,
.ie8 .ttip:hover:after {
  display: block;
}
.ie9 .radio.checked i,
.ie9 .checkbox.checked i {
  top: 0;
}
header {
  position: relative;
  top: 0px;
  right: 0;
  left: 0;
  background: white;
  height: 90px;
  z-index: 9;
}
.logo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  background: #0283b1;
  width: 140px;
}
.logo img {
  width: 100px;
}
.nav {
  margin: auto;
  text-align: center;
}
.nav li {
  display: inline-block;
  height: 90px;
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
}
.nav li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  color: #333;
  padding: 0px 15px;
}
.nav li:hover a {
  color: white;
}
li.about-nav:hover a.primary-link {
  background: #18a5dd;
}
li.go-nav:hover a.primary-link {
  background: #4a822b;
}
li.do-nav:hover a.primary-link {
  background: #0283b1;
}
li.press-nav:hover a.primary-link {
  background: #ee3124;
}
li.plan-nav:hover a.primary-link {
  background: #e28c25;
}
li.eat-nav:hover a.primary-link {
  background: #2e358f;
}
.contact {
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
}
.contact ul {
  margin: auto;
  padding-right: 20px;
}
.contact ul li {
  display: inline-block;
  padding: 0px 2px;
}
.contact ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.contact ul li.search {
  padding-left: 60px;
}
.dropdown {
  display: none;
  position: absolute;
  width: 100%;
  top: 90px;
  left: 0;
  right: 0;
  padding: 30px 0;
}
.dropdown ul {
  display: block;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.dropdown ul li {
  display: block;
  text-align: left;
  height: auto;
}
.dropdown ul li a {
  display: block;
  text-align: left;
  color: white;
  height: auto;
  font-family: 'HelveticaNeueW01-45Ligh';
  text-transform: none;
}
.primary-item:hover .dropdown {
  display: block;
}
.about-nav .dropdown {
  background: rgba(24, 165, 221, 0.9);
}
.go-nav .dropdown {
  background: rgba(74, 130, 43, 0.9);
}
.do-nav .dropdown {
  background: rgba(2, 131, 177, 0.9);
}
.press-nav .dropdown {
  background: rgba(238, 49, 36, 0.9);
}
.eat-nav .dropdown {
  background: rgba(46, 53, 143, 0.9);
}
.plan-nav .dropdown {
  background: rgba(226, 140, 37, 0.9);
}
.subnav-wrapper {
  border-left: 1px solid white;
}
.dropdown li a {
  background: transparent;
}
.dropdown p {
  color: white;
  text-transform: none;
  text-align: left;
}
.section-headline {
  font-family: 'HelveticaNeueW01-65Medi';
  font-size: 20px;
}
.dropdown-column {
  display: flex;
}
.dropdown-column div {
  margin: auto;
}
header .alert {
  background: #ee3124;
  padding: 12px 0;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  font-size: 14px;
}
header .alert .update-title {
  display: inline-block;
  color: #18a5dd;
  text-transform: uppercase;
  padding-right: 30px;
}
header .alert .row .columns {
  margin: auto;
}
hr {
  border: none;
  background: none;
  border-bottom: 1px solid #ddd;
}
.lead {
  margin-bottom: 20px;
}
.slider,
.hero {
  position: relative;
  clear: both;
  margin: auto;
  max-width: 100%;
  height: 60vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 569px;
  overflow: hidden;
  text-align: center;
}
.preload {
  opacity: 0;
  filter: blur(2px);
}
.slider img,
.hero img {
  width: 100%;
  opacity: 1;
  transition: .2s linear all;
}
.slider img[data-src],
.hero img[data-src] {
  opacity: 0;
}
.hero-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#85000000', GradientType=0);
}
.hero-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 60px;
  color: white;
  text-align: left;
  font-size: 23px;
}
.hero-text span {
  display: block;
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  font-size: 18px;
}
.home-intro {
  padding: 30px 0;
}
.home-intro h1 {
  font-size: 24px;
}
.home-intro .subhead {
  color: #333;
}
.belt {
  padding: 50px 0;
}
.belt-title {
  padding-bottom: 20px;
}
.sections {
  position: relative;
  background: white;
  padding: 40px 0;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.section-list {
  position: relative;
  width: 15%;
  z-index: 2;
}
.section-list li {
  position: relative;
  height: 40px;
}
.section-list li a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: right;
  color: #333;
  font-family: 'HelveticaNeueW01-55Roma';
  text-transform: uppercase;
  font-size: 14px;
  line-height: 37px;
  letter-spacing: 1px;
  padding-right: 20px;
  transition: .2s linear all;
}
.section-list li a:hover:before,
.section-list li.active a:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: '';
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4f000000', endColorstr='#00000000', GradientType=1);
}
.section-list li:hover a,
.section-list li.active a {
  color: white;
  right: -70px;
}
.section-list li.about.active a,
.section-list li.about:hover a {
  background: #18a5dd;
}
.section-list li.go.active a,
.section-list li.go:hover a {
  background: #4a822b;
}
.section-list li.do.active a,
.section-list li.do:hover a {
  background: #0283b1;
}
.section-list li.stay.active a,
.section-list li.stay:hover a {
  background: #25ada6;
}
.section-list li.press.active a,
.section-list li.press:hover a {
  background: #ee3124;
}
.section-list li.eat.active a,
.section-list li.eat:hover a {
  background: #2e358f;
}
.section-list li.plan.active a,
.section-list li.plan:hover a {
  background: #e28c25;
}
.section-list li.gnp.active a,
.section-list li.gnp:hover a {
  background: #333;
}
.section-image {
  display: none;
  position: absolute;
  top: 0;
  left: 15%;
  bottom: 0;
  width: 35%;
  height: 100%;
}
.about-image {
  background: url('/assets/img/section/about.jpg');
  background-size: cover;
  background-position: center center;
}
.go-image {
  background: url('/assets/img/section/go.jpg');
  background-size: cover;
  background-position: center center;
}
.do-image {
  background: url('/assets/img/section/do.jpg');
  background-size: cover;
  background-position: center center;
}
.stay-image {
  background: url('/assets/img/section/stay.jpg');
  background-size: cover;
  background-position: center center;
}
.eat-image {
  background: url('/assets/img/section/eat.jpg');
  background-size: cover;
  background-position: center center;
}
.plan-image {
  background: url('/assets/img/section/plan.jpg');
  background-size: cover;
  background-position: center center;
}
.section-content {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  padding: 35px;
}
.section-content.display,
.section-image.display {
  display: block;
}
.about-content {
  background: #18a5dd;
}
.go-content {
  background: #4a822b;
}
.do-content {
  background: #0283b1;
}
.stay-content {
  background: #25ada6;
}
.press-content {
  background: #ee3124;
}
.eat-content {
  background: #2e358f;
}
.plan-content {
  background: #e28c25;
}
.section-content h2 {
  font-family: 'HelveticaNeueW01-55Roma';
  font-size: 18px;
  color: white;
  padding-bottom: 30px;
}
.section-content p {
  font-family: 'HelveticaNeueW01-45Ligh';
  font-size: 16px;
  color: white;
}
.section-content a {
  display: block;
  position: absolute;
  bottom: 0px;
  right: 20px;
  color: white;
}
.section-content a i {
  font-size: 40px;
}
.home-logo {
  position: absolute;
  margin: auto;
  display: flex;
  max-width: 600px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  transition: .5s linear all;
}
.home-logo img {
  width: 100%;
}
.homeboxes {
  padding: 30px 0;
}
.homeboxes li.box {
  position: relative;
}
.homeboxes li.box .text.red {
  color: #ee3124;
}
.homeboxes li.box .text.forest {
  color: #3d582b;
}
.homeboxes li.box .text.orange {
  color: #e28c25;
}
.homeboxes li.box .text.dblue {
  color: #1b6390;
}
.homeboxes li.box .text.teal {
  color: #25ada6;
}
li.box a {
  height: 200px;
  overflow: hidden;
}
.box a.forest {
  color: #3d582b;
  border: 1px solid #3d582b;
}
.box a.teal {
  color: #25ada6;
  border: 1px solid #25ada6;
}
.box a.dblue {
  color: #1b6390;
  border: 1px solid #1b6390;
}
.box a.red {
  color: #ee3124;
  border: 1px solid #ee3124;
}
.box a.orange {
  color: #e28c25;
  border: 1px solid #e28c25;
}
.box a:after {
  position: absolute;
  right: 25px;
  bottom: 15px;
  font-size: 40px;
  font-family: "Ionicons";
  content: "\f3d6";
  transition: .2s linear all;
}
.text.forest:after {
  color: #3d582b;
}
.text.teal:after {
  color: #25ada6;
}
.text.dblue:after {
  color: #1b6390;
}
.text.red:after {
  color: #ee3124;
}
.text.orange:after {
  color: #e28c25;
}
.halfbox {
  display: block;
  position: relative;
  padding: 15px;
  border: 1px solid #18a5dd;
  font-size: 24px;
  color: #333;
  transition: .2s linear all;
  margin-bottom: 28px;
}
.halfbox:hover {
  background: white;
  border: 1px solid white;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.halfbox span,
.call span {
  display: block;
  color: #18a5dd;
  font-size: 12px;
  letter-spacing: .5px;
  font-family: 'HelveticaNeueW01-65Medi';
  text-transform: uppercase;
}
.halfbox:after {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  right: 10px;
  top: 0;
  bottom: 0;
  font-family: "Ionicons";
  content: "\f3d6";
  font-size: 35px;
  color: #18a5dd;
  transition: .2s linear all;
}
.halfbox:hover:after {
  right: 0;
}
.page-box {
  position: relative;
  width: 100%;
  border: 2px solid #1b6390;
  cursor: pointer;
  margin-bottom: 50px;
}
.page-box img {
  margin-top: 25px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 300px;
}
.page-box p {
  padding: 20px;
  color: #555;
}
.page-box h2 {
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  background-color: #0283b1;
  color: #fff;
  transition: .2s linear all;
}
.page-box p:after {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  right: 15px;
  bottom: 0;
  font-family: "Ionicons";
  content: "\f3d6";
  font-size: 35px;
  color: #1b6390;
  transition: .2s linear all;
}
.page-box:hover {
  border-color: #18a5dd;
}
.page-box:hover h2 {
  background-color: #18a5dd;
}
.page-box:hover p:after {
  right: 5px;
}
.premium {
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  margin: 30px auto;
  transition: .2s linear all;
}
.premium p,
.premium span {
  color: #333;
}
.premium:hover {
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.premium-content {
  padding: 20px;
}
.premium-content h4 {
  font-family: 'HelveticaNeueW01-55Roma';
  font-size: 18px;
}
.pemium-logo {
  position: relative;
  display: flex;
  height: 171px;
  text-align: center;
  background: #ddd;
  border: 1px solid #ddd;
  padding-bottom: 50px;
}
.premium-cta {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  background: white;
  text-align: left;
  padding: 12px;
}
.premium-cta-arrow {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ee3124;
  width: 50px;
}
.premium-cta-arrow i {
  font-size: 28px;
  color: white;
}
.pemium-logo img {
  margin: auto;
  max-width: 60%;
}
.belt .premium {
  margin: auto;
}
.update {
  padding: 12px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.update .row {
  display: flex;
}
.update .row .columns {
  margin: auto;
}
.update p,
.update a,
.update span {
  font-size: 14px;
}
.update-title {
  display: inline-block;
  color: #18a5dd;
  text-transform: uppercase;
  padding-right: 30px;
}
.ghost {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #18a5dd;
  text-align: center;
  text-transform: uppercase;
  transition: .2s linear all;
  padding: 5px 0;
}
.ghost i {
  font-size: 21px;
  margin-right: 8px;
}
.ghost:hover {
  background: #18a5dd;
  color: white;
}
.about a,
.about .ghost,
.about .update-title,
.about .call span,
.about .halfbox span,
.about .halfbox:after {
  color: #18a5dd;
}
.about .ghost,
.about .halfbox {
  border: 1px solid #18a5dd;
}
.about .ghost:hover {
  background: #18a5dd;
}
.go a,
.go .ghost,
.go .update-title,
.go .call span,
.go .halfbox span,
.go .halfbox:after {
  color: #4a822b;
}
.go .ghost,
.go .halfbox {
  border: 1px solid #4a822b;
}
.go .ghost:hover {
  background: #4a822b;
}
.do a,
.do .ghost,
.do .update-title,
.do .call span,
.do .halfbox span,
.do .halfbox:after {
  color: #0283b1;
}
.do .ghost,
.do .halfbox {
  border: 1px solid #0283b1;
}
.do .ghost:hover {
  background: #0283b1;
}
.stay a,
.stay .ghost,
.stay .update-title,
.stay .call span,
.stay .halfbox span,
.stay .halfbox:after {
  color: #25ada6;
}
.stay .ghost,
.stay .halfbox {
  border: 1px solid #25ada6;
}
.stay .ghost:hover {
  background: #25ada6;
}
.press a,
.press .ghost,
.press .update-title,
.press .call span,
.press .halfbox span,
.press .halfbox:after {
  color: #ee3124;
}
.press .ghost,
.press .halfbox {
  border: 1px solid #ee3124;
}
.press .ghost:hover {
  background: #ee3124;
}
.eat a,
.eat .ghost,
.eat .update-title,
.eat .call span,
.eat .halfbox span,
.eat .halfbox:after {
  color: #2e358f;
}
.eat .ghost,
.eat .halfbox {
  border: 1px solid #2e358f;
}
.eat .ghost:hover {
  background: #2e358f;
}
.plan a,
.plan .update-title,
.plan .call span,
.plan .halfbox span,
.plan .halfbox:after {
  color: #e28c25;
}
.plan .ghost,
.plan .halfbox {
  border: 1px solid #e28c25;
}
.plan .ghost:hover {
  background: #e28c25;
}
.about .halfbox,
.go .halfbox,
.do .halfbox,
.stay .halfbox,
.eat .halfbox,
.plan .halfbox,
.press .halfbox {
  color: #333;
}
.about .ghost:hover,
.go .ghost:hover,
.do .ghost:hover,
.stay .ghost:hover,
.eat .ghost:hover,
.plan .ghost:hover,
.press .ghost:hover {
  color: white;
}
.glacier-park a,
.glacier-park .ghost,
.glacier-park .update-title,
.glacier-park .call span,
.glacier-park .halfbox span,
.glacier-park .halfbox:after {
  color: #3d582b;
}
.glacier-park .ghost,
.glacier-park .halfbox {
  border: 1px solid #3d582b;
}
.glacier-park .ghost:hover {
  background: #3d582b;
}
.post {
  border-bottom: 1px solid #333;
}
.post-image {
  background: #ccc;
  background-position: cover;
  height: 200px;
}
.post-title {
  font-size: 15px;
}
.post-date p,
.post-link p {
  font-size: 11px;
}
.post-link p {
  text-align: right;
}
.communities,
.twitter {
  position: relative;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
}
.communities:after {
  position: absolute;
  right: 0;
  top: 8px;
  bottom: 0;
  font-family: "Ionicons";
  content: "\f3d0";
  font-size: 30px;
  color: #333;
}
.communities ul {
  position: absolute;
  height: 0px;
  width: 100%;
  text-align: left;
  transition: linear .2s all;
  overflow: hidden;
  margin-bottom: 0px;
}
.communities ul input {
  width: 100%;
  padding: 10px;
  background-color: #ddd;
  margin: 0px;
  border: none;
}
.communities ul li {
  background: #ddd;
  width: 99%;
  padding: 10px 12px;
  margin-bottom: 7px;
  border: 1px solid white;
  transition: .2s linear all;
}
.communities ul li:hover {
  background-color: white;
}
.communities ul.internal-communities {
  background-color: #fff!important;
  border: 1px solid #333;
}
.communities ul.internal-communities input {
  background-color: #fff;
}
.communities ul.internal-communities a {
  display: inherit;
}
.communities ul.internal-communities li {
  background: #fff;
  color: #333;
  width: 100%;
  padding: 2px 12px;
  margin-bottom: 2px;
  border: none;
}
.communities ul.internal-communities li:hover {
  background-color: #ddd;
}
.communities:hover ul {
  height: 250px;
  background-color: #e2e3e4;
  z-index: 999;
  width: 100%;
  overflow: scroll;
}
.twitter h4 {
  padding: 15px 0;
}
.twitter p {
  font-size: 12px;
}
.call {
  padding-top: 8px;
}
.call span {
  padding: 0;
  margin: 0;
}
.call p {
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: #333;
}
.footer-ad {
  margin-top: 15px;
}
footer {
  padding: 30px 0 100px 0;
}
.footer-contact p {
  padding-top: 10px;
  line-height: 22px;
}
.footer-contact img {
  max-width: 55%;
}
.footer-contact li {
  display: inline-block;
  margin-right: 2px;
}
.ad {
  margin-top: 25px;
}
.turbolinks-progress-bar {
  height: 2px;
  background-color: #18a5dd;
}
.notification {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 20%;
  min-width: 200px;
  padding: 20px 60px 20px 20px;
  background: white;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
  z-index: 99999999;
}
.notification .ignore {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  transition: .2s linear all;
}
.notification .ignore i {
  color: white;
  font-size: 30px;
}
footer li a {
  color: #555555;
}
.notification:hover .ignore {
  background: #666666;
}
.expander.btn {
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  transition: .2s linear all;
}
.northern-tier .content,
.southern-tier .content {
  height: 0px;
  overflow: hidden;
  transition: .2s linear all;
}
.expandable {
  height: 0px;
  overflow: hidden;
}
.expandable.expand {
  height: auto;
  padding-top: 15px;
  transition: .2s linear all;
}
.northern-tier.expand .content,
.southern-tier.expand .content {
  height: auto;
  padding-top: 15px;
}
.i4ewOd-pzNkMb-haAclf {
  background-color: #1b6390;
}
.i4ewOd-pzNkMb-ornU0b-b0t70b-Bz112c {
  display: none;
}
.img_content {
  width: 60%;
  display: block;
  margin: auto;
}
.list {
  list-style-type: disc;
  padding-left: 18px;
}
.quicksearch {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  height: 90px;
  z-index: 9;
}
.quicksearch .search-input {
  height: 90px;
  width: 80%;
}
.quicksearch .search-button {
  width: 20%;
  height: 90px;
}
.quicksearch .search-button span {
  margin: auto;
}
.quicksearch .search-button i {
  transition: .2s linear all;
  cursor: pointer;
}
.quicksearch .search-button i:hover {
  color: #0283b1;
}
.quicksearch input {
  font-family: 'HelveticaNeueW01-Thin';
  font-size: 35px;
  border: none;
  border-bottom: 1px solid transparent;
  transition: .2s linear all;
  margin-bottom: 0;
}
.quicksearch input:focus {
  font-size: 35px;
  border: none;
  border-bottom: 1px solid transparent;
}
.quicksearch i {
  font-size: 40px;
}
.quicksearch .search-close {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 5px;
  width: 55px;
}
.quicksearch .search-close i {
  cursor: pointer;
  margin: auto;
}
.search-toggle i {
  font-size: 21px;
}
.search-wrap,
.fixed-header {
  position: fixed;
  background: white;
  top: 90px;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
  padding: 20px 0;
  z-index: 8;
}
.search-results-wrapper {
  padding-top: 100px;
}
.big-search input {
  font-family: 'HelveticaNeueW01-Thin';
  font-size: 35px;
  border: none;
  border-bottom: 1px solid transparent;
  margin-bottom: 0;
  transition: .2s linear all;
}
.big-search input:focus {
  font-size: 35px;
  border: none;
  border-bottom: 1px solid transparent;
}
.big-search i {
  cursor: pointer;
  font-size: 40px;
  transition: .2s linear all;
}
.big-search i:hover {
  color: #0283b1;
}
.search {
  cursor: pointer;
}
.search-results {
  padding: 50px 0;
}
.search-results h2 {
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  font-size: 16px;
  padding-bottom: 20px;
}
.result {
  display: block;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 25px;
  min-height: 208px;
}
.result h4 {
  color: #0283b1;
}
.result p {
  color: #333;
}
.result:hover {
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.deal,
.trip-item {
  display: block;
  border: 1px solid #ddd;
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 25px;
  transition: .2s linear all;
}
.trip-item:hover {
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.valid {
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  color: #0283b1;
}
.deal:hover {
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.photo-credit {
  display: inline-flex;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  color: white;
}
.photo-credit i {
  font-size: 24px;
  padding-right: 8px;
}
.accentbox {
  background: #ddd;
  padding: 20px;
  margin: 30px 0;
}
.theme {
  background-color: #ee3124;
  height: 250px;
}
.theme p,
.theme h2,
.theme h3 {
  color: #fff;
}
.carousel {
  margin-bottom: 30px;
  height: 250px;
  display: flex;
  align-items: center;
  transition: .2s linear all;
}
.carousel:hover {
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.carousel-tab {
  padding: 20px;
}
.carousel-tab p {
  margin-top: 52px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.carousel-content {
  padding: 20px;
}
.carousel-content h3 {
  font-family: 'HelveticaNeueW01-55Roma';
  font-size: 18px;
  color: #ee3124;
  text-transform: uppercase;
}
.carousel-content p {
  color: #333;
}
.carousel-row {
  position: relative;
  height: 250px;
  background-color: #fff;
}
.contact-shot {
  width: 80%;
  border: solid #f2f2f2 3px;
}
.card {
  position: absolute;
  pointer-events: none;
  width: 100%;
}
.card .premium {
  border: none;
  padding: 40px;
}
.card .carousel-tab {
  opacity: 0;
}
.card .carousel-content {
  opacity: 0;
  transition: linear .45s all;
}
.card.current {
  pointer-events: all;
}
.card.current .carousel-content {
  opacity: 1;
  transition: linear .45s all;
}
.card.current .carousel-tab {
  opacity: 1;
}
.carousel-select {
  border-top: 1px solid #ddd;
  position: relative;
  bottom: 30px;
  margin-left: 70%;
  text-align: right;
}
.carousel-select .ion-chevron-right:before {
  padding-left: 10px;
}
.carousel-select a {
  cursor: pointer;
}
.carousel-select ul {
  display: inline-flex;
}
.carousel-select ul li {
  padding-left: 8px;
  padding-right: 8px;
  color: #919191;
  cursor: pointer;
}
.carousel-select ul li.current {
  color: #ee3124;
}
.slider {
  position: relative;
}
.photo-credit {
  display: inline-flex;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  color: white;
}
.photo-credit i {
  font-size: 24px;
  padding-right: 8px;
}
html {
  position: relative;
  border-top: 40px solid #f2f2f2;
  background: white;
}
body {
  position: initial;
  background: white;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.second-nav {
  position: absolute;
  top: -35px;
  right: 90px;
  display: flex;
  align-items: center;
  justify-content: right;
}
.second-nav li {
  display: inline-block;
  margin: auto 15px;
}
.second-nav a {
  color: #333 !important;
  text-transform: uppercase;
  font-family: 'HelveticaNeueW01-57Cn';
  font-size: 12px;
  transition: .2s linear all;
}
@media (max-width: 779px) {
  .carousel-content {
    padding: 20px!important;
  }
  .carousel-tab {
    padding: 20px!important;
  }
  .carousel-tab p {
    margin-top: 0px;
  }
  .carousel {
    display: block;
    height: 380px;
  }
  .theme {
    height: auto;
  }
  .carousel-select {
    margin-left: 0px;
  }
  .carousel-row {
    height: 380px;
  }
}
@media (max-width: 500px) {
  .carousel,
  .carousel-row {
    height: 530px;
  }
}
@media (max-width: 350px) {
  .carousel,
  .carousel-row {
    height: 630px;
  }
}
.centering {
  display: block;
  margin: auto;
}
.centering img {
  display: block;
  margin: auto;
}
.listing {
  padding-top: 10px;
  padding-bottom: 10px;
}
.listing .image {
  background-size: contain;
  background-position: center;
  height: 220px;
}
.listing a h2 {
  color: #1b6390;
}
.listing .listing-bar {
  height: 50px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.listing .listing-bar a {
  text-transform: uppercase;
  font-size: .8em;
  line-height: 50px;
  padding: 10px;
}
.listing .listing-bar a i {
  color: #333;
  padding-right: 10px;
  font-size: 1.5em;
}
.listing .listing-bar a.trip-link {
  position: relative;
  background-color: #ee3124;
  color: #fff;
  float: right;
  height: 48px;
  line-height: 48px;
  padding: 0px 20px 0px 20px;
  transition: linear .2s all;
}
.listing .listing-bar a.trip-link:hover {
  margin-right: 3px;
  background-color: #1b6390;
}
.listing .listing-bar .ion-ios-plus-empty {
  position: absolute;
  padding: 0px 20px 0px 20px;
  margin-left: 20px;
  color: #fff;
  font-size: 2em;
  background-color: #1b6390;
}
.listing-search input {
  margin-top: 10px;
}
.listing-search #CityInput {
  display: block;
  width: 50%;
  margin-bottom: 25px;
  padding: 8px 5px;
  border: 1px solid #e2e3e4;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: .2s linear all;
  border-radius: 0px;
  background-color: #fff;
}
.subheader {
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  padding: 8px 20%;
  z-index: 5;
  background: #333;
}
.subheader h1 {
  color: #ddd;
  color: white;
  text-transform: uppercase;
  font-size: 25px;
}
.section-nav {
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 20%;
  cursor: pointer;
}
.section-nav p {
  margin: auto auto auto 25px;
  letter-spacing: 1px;
  color: white;
  padding-right: 100px;
  text-transform: uppercase;
  font-family: 'HelveticaNeueW01-57Cn';
}
.section-nav .drop-icon {
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  /*
		i:before {
			.icon;
			content: "\f3d0";
			font-size: 20px;
			.transition;
		}
		*/
}
.section-nav .drop-icon i {
  margin: auto;
  font-size: 28px;
  transition: .2s linear all;
}
.section-nav .drop-icon.active i {
  transform: rotate(45deg);
  /* content: "\f404"; */
}
.section-nav .drop-more,
.section-nav .drop-less {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40px;
  top: 0;
  bottom: 0;
  padding-right: 10px;
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  color: white;
  margin: auto;
  transition: .2s linear all;
}
.section-nav .drop-more.active {
  opacity: 0;
}
.section-nav .drop-less {
  opacity: 0;
}
.section-nav .drop-less.active {
  opacity: 1;
}
.section-nav-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  width: 20%;
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.section-nav-dropdown li {
  margin: 0;
}
.section-nav-dropdown li a {
  color: #333;
  text-transform: uppercase;
}
.sectionboxes .box a {
  height: 95px;
}
.sectionboxes .box .text {
  display: block;
  position: relative;
  padding: 15px 40px 60px 15px;
  line-height: 32px;
}
li.box {
  margin-bottom: 20px;
}
li.box a {
  display: block;
  position: relative;
  padding: 10px;
  color: #333;
  font-size: 20px;
  line-height: 32px;
  border: 1px solid #333;
}
li.box a:hover {
  background: white;
  border: 1px solid white;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.box a:after {
  position: absolute;
  right: 25px;
  bottom: 15px;
  font-size: 40px;
  font-family: "Ionicons";
  content: "\f3d6";
  color: #333;
  transition: .2s linear all;
}
.box a:hover:after {
  right: 15px;
}
.discover {
  text-align: right;
}
.discover h4 {
  color: #18a5dd;
}
.discover li {
  display: block;
  width: 100%;
}
.discover li a {
  color: #333;
}
.carousel-wrap {
  position: relative;
}
.carousel-arrows {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 50px;
}
.carousel-arrows i {
  color: white;
  font-size: 32px;
}
.arrow-next {
  right: 0;
}
.arrow-last {
  left: 0;
}
.lower-content {
  padding-bottom: 40px;
}
.lower-content .discover {
  text-align: left;
}
.about .logo {
  background: #18a5dd;
}
.about .discover h4 {
  color: #18a5dd;
  font-size: 28px;
  letter-spacing: 1px;
}
.about .subheader {
  background: #18a5dd;
}
.about li.about-nav a.primary-link {
  color: white;
  background: #18a5dd;
}
.about h1 {
  color: #18a5dd;
}
.about .box a {
  color: #18a5dd;
  border: 1px solid #18a5dd;
}
.about .box a:after {
  color: #18a5dd;
}
.go .logo {
  background: #4a822b;
}
.go .discover h4 {
  color: #4a822b;
  font-size: 28px;
  letter-spacing: 1px;
}
.go .subheader {
  background: #4a822b;
}
.go li.go-nav:hover a.primary-link {
  color: white;
  background: #4a822b;
}
.go .intro-header {
  color: #4a822b;
}
.go .home-intro a {
  font-family: 'HelveticaNeueW01-65Medi';
}
.go .box a {
  color: #4a822b;
  border: 1px solid #4a822b;
  transition: .2s linear all;
}
.go .expander.btn {
  border: 1px solid #4a822b;
  color: #4a822b;
}
.go .expander.btn.expand {
  background-color: #4a822b;
  color: #fff;
}
.go .box .desc.blue {
  color: #4a822b;
  border: 1px solid #4a822b;
}
.go .box .desc.blue:after {
  color: #4a822b;
}
.go .box a:after {
  color: #4a822b;
  transition: .2s linear all;
}
.go .box a:hover:after {
  color: #4a822b;
}
.do .logo {
  background: #0283b1;
}
.do .discover h4 {
  color: #0283b1;
  font-size: 28px;
  letter-spacing: 1px;
}
.do .subheader {
  background: #0283b1;
}
.do li.do-nav a.primary-link {
  color: white;
  background: #0283b1;
}
.do h1 {
  color: #0283b1;
}
.do .box a {
  color: #0283b1;
  border: 1px solid #0283b1;
}
.do .box a:after {
  color: #0283b1;
}
.stay .logo {
  background: #25ada6;
}
.stay .discover h4 {
  color: #25ada6;
  font-size: 28px;
  letter-spacing: 1px;
}
.stay .subheader {
  background: #25ada6;
}
.stay li.stay-nav:hover a.primary-link {
  color: white;
  background: #25ada6;
}
.stay h1 {
  color: #25ada6;
}
.stay .box a {
  color: #25ada6;
  border: 1px solid #25ada6;
}
.stay .box a:after {
  color: #25ada6;
}
.press .logo {
  background: #ee3124;
}
.press .discover h4 {
  color: #ee3124;
  font-size: 28px;
  letter-spacing: 1px;
}
.press .subheader {
  background: #ee3124;
}
.press li.press-nav:hover a.primary-link {
  color: white;
  background: #ee3124;
}
.press h1 {
  color: #ee3124;
}
.press .box a {
  color: #ee3124;
  border: 1px solid #ee3124;
}
.press .box a:after {
  color: #ee3124;
}
.eat .logo {
  background: #2e358f;
}
.eat .discover h4 {
  color: #2e358f;
  font-size: 28px;
  letter-spacing: 1px;
}
.eat .subheader {
  background: #2e358f;
}
.eat li.eat-nav a.primary-link {
  color: white;
  background: #2e358f;
}
.eat h1 {
  color: #2e358f;
}
.eat .box a {
  color: #2e358f;
  border: 1px solid #2e358f;
}
.eat .box a:after {
  color: #2e358f;
}
.plan .logo {
  background: #e28c25;
}
.plan .discover h4 {
  color: #e28c25;
  font-size: 28px;
  letter-spacing: 1px;
}
.plan .subheader {
  background: #e28c25;
}
.plan li.plan-nav a.primary-link {
  color: white;
  background: #e28c25;
}
.plan h1 {
  color: #e28c25;
}
.plan .box a {
  color: #e28c25;
  border: 1px solid #e28c25;
}
.plan .box a:after {
  color: #e28c25;
}
.filter {
  margin-top: 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.filter input {
  border: none;
  margin-bottom: 0;
}
.filter-icons {
  text-align: right;
}
.filter-icons i {
  display: inline-block;
  font-size: 23px;
  padding: 3px 5px 0 5px;
  color: #b3b3b3;
  cursor: pointer;
  transition: .2s linear all;
}
.filter-icons i:hover {
  color: #333;
}
.filter-icons .active {
  color: #333;
}
.centering {
  display: block;
  margin: auto;
  text-align: center;
}
.centering img {
  display: block;
  margin: auto;
}
.listing {
  padding-top: 10px;
  padding-bottom: 10px;
}
.listing .image {
  background-size: contain;
  background-position: center;
  height: 220px;
}
.listing .listing-bar {
  position: relative;
  height: 50px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.listing .listing-bar a {
  text-transform: uppercase;
  font-size: .8em;
  line-height: 50px;
  padding: 10px;
}
.listing .listing-bar a i {
  color: #333;
  padding-right: 10px;
  font-size: 1.5em;
}
.listing .listing-bar a.trip-link {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  top: 0;
  bottom: 0;
  right: 0;
  height: 48px;
  line-height: 48px;
  padding: 0px 70px 0px 20px;
}
.listing .listing-bar .more-icon {
  position: absolute;
  padding: 0px 20px 0px 20px;
  right: 0;
  color: white;
  font-size: 2em;
  background-color: rgba(0, 0, 0, 0.2);
  transition: .2s linear all;
}
.listing .listing-bar a.trip-link:hover .more-icon {
  padding: 0px 15px 0px 25px;
}
.listing-contact a {
  color: #333;
}
.listing-map {
  border: 5px solid #ddd;
  margin-top: 30px;
}
.listing-map h3 {
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  font-size: 15px;
}
.map-listings h3 {
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  font-size: 15px;
}
.listing-more {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
}
.listing-more strong {
  font-family: 'HelveticaNeueW01-65Medi';
  font-weight: normal;
}
.listing-more h4 {
  color: #0283b1;
  text-transform: uppercase;
  font-family: 'HelveticaNeueW01-57Cn';
  font-size: 16px;
  padding-top: 10px;
}
#image-slider {
  position: relative;
  z-index: 3;
}
#image-slider .owl-wrapper-outer {
  border: 5px solid #ddd;
}
.state-listing {
  border-top: 1px solid #ddd;
  padding: 15px 0;
}
.state-listing li {
  display: inline-block;
}
.state-listing li.city {
  width: 25%;
}
.state-listing li.phone {
  width: 25%;
}
.state-listing li.website {
  width: 25%;
}
.state-listing li.address {
  width: 25%;
}
.state-listings {
  /* border-bottom: 1px solid @light; */
}
.image-slide {
  height: 256px;
}
.list-listings,
.map-listings {
  display: none;
}
.list-listings.active,
.map-listings.active {
  display: block;
}
.about a.trip-link:hover,
.about a.trip-link {
  background-color: #18a5dd;
}
.about .listing-bar a {
  color: #18a5dd;
}
.go a.trip-link:hover,
.go a.trip-link {
  background-color: #4a822b;
}
.go .listing-bar a {
  color: #4a822b;
}
.do a.trip-link:hover,
.do a.trip-link {
  background-color: #0283b1;
}
.do .listing-bar a {
  color: #0283b1;
}
.stay a.trip-link:hover,
.stay a.trip-link {
  background-color: #25ada6;
}
.stay .listing-bar a {
  color: #25ada6;
}
.press a.trip-link:hover,
.press a.trip-link {
  background-color: #ee3124;
}
.press .listing-bar a {
  color: #ee3124;
}
.eat a.trip-link:hover,
.eat a.trip-link {
  background-color: #2e358f;
}
.eat .listing-bar a {
  color: #2e358f;
}
.plan a.trip-link:hover,
.plan a.trip-link {
  background-color: #e28c25;
}
.plan .listing-bar a {
  color: #e28c25;
}
.trip-link {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  bottom: 0;
  color: white;
  background: #1b6390;
  padding: 0 60px 0 10px;
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
}
.trip-link .icon-wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  background: rgba(0, 0, 0, 0.2);
  margin-left: 20px;
  font-size: 30px;
}
.trip-link li {
  display: block;
}
.daylist {
  display: none;
  position: absolute;
  right: 0;
  top: 49px;
  background: #ddd;
  width: 146px;
  padding: 20px;
  z-index: 20;
}
.trip-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 80px;
  z-index: 1000;
}
.trip-topper {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background: #333;
  height: 80px;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.trip-topper span {
  font-size: 21px;
  color: white;
}
.trip-trigger {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  align-items: center;
  justify-content: center;
  background: #1b6390;
}
.trip-trigger i {
  color: white;
  font-size: 50px;
}
.plus-wrap,
.icon-wrap i {
  transition: .2s linear all;
}
.plus-wrap.active,
.icon-wrap.active i {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.trip-info {
  display: none;
  background: white;
  padding: 30px 0 60px 0;
}
.logout {
  color: #1b6390;
  transition: .2s linear all;
}
.logout:hover {
  color: #0b283a;
}
.day-nav {
  text-align: left;
  padding-bottom: 30px;
}
.day-nav li {
  display: block;
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  padding: 5px;
  color: #ddd;
  border-bottom: 1px solid white;
  transition: .2s linear all;
}
.day-nav li.active {
  color: #333;
}
.day-wrap {
  display: none;
}
.day-wrap.active {
  display: block;
}
.trip-stop {
  display: block;
  position: relative;
  padding: 15px 40px 60px 15px;
  font-family: 'HelveticaNeueW01-45Ligh';
  font-size: 30px;
  line-height: 40px;
  height: 200px !important;
  overflow: hidden;
  border: 1px solid #333;
  margin-bottom: 20px;
}
.trip-stop .delete {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 40px;
  color: #333;
  transition: .2s linear all;
}
.trip-stop .delete:hover {
  color: #ee3124;
}
.trip-builder {
  padding-bottom: 10vh;
}
.trip-box {
  background: white;
  margin-bottom: 30px;
  padding: 10px;
  transition: .2s linear all;
}
.trip-box:hover {
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.secondary-header {
  background: #333;
  text-align: center;
  padding: 40px;
}
.secondary-header h1 {
  color: white;
}
.about .secondary-header {
  background: #18a5dd;
}
.go .secondary-header {
  background: #4a822b;
}
.do .secondary-header {
  background: #0283b1;
}
.stay .secondary-header {
  background: #25ada6;
}
.eat .secondary-header {
  background: #2e358f;
}
.plan .secondary-header {
  background: #e28c25;
}
.about .trip-trigger {
  background: #18a5dd;
}
.go .trip-trigger {
  background: #4a822b;
}
.do .trip-trigger {
  background: #0283b1;
}
.stay .trip-trigger {
  background: #25ada6;
}
.eat .trip-trigger {
  background: #2e358f;
}
.plan .trip-trigger {
  background: #e28c25;
}
input {
  display: inline;
  width: 100%;
  margin-bottom: 25px;
  padding: 8px 5px;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: .2s linear all;
  font-size: 1em;
}
.select-wrapper {
  position: relative;
}
select {
  margin-bottom: 25px;
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  font-size: 14px;
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  transition: .2s linear all;
}
.select-wrapper:after {
  position: absolute;
  right: 8px;
  top: 2px;
  bottom: 0;
  font-family: "Ionicons";
  content: '\f3d0';
  pointer-events: none;
}
input[type="submit"] {
  cursor: pointer;
  margin-top: 40px;
  font-size: 17px;
  color: white;
  text-transform: uppercase;
  font-family: 'HelveticaNeueW01-57Cn';
  background: #ee3124;
  transition: .2s linear all;
}
input[type="submit"]:hover {
  background: #e72012;
}
.guideform select {
  display: block;
  width: 100%;
  margin-left: 0;
  padding: 6.5px;
}
.guideform .formgroup {
  text-transform: uppercase;
  font-family: 'HelveticaNeueW01-57Cn';
  color: #ee3124;
}
.reset {
  display: inline-block;
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  padding-top: 3px;
}
input:focus,
select:focus {
  border: 1px solid #333;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
  position: relative;
  display: inline-block;
  width: auto;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 10px 0 0;
  transition: .2s linear all;
}
input[type="checkbox"]:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 4px;
  font-family: "Ionicons";
  font-size: 20px;
  content: "\f401";
  transition: .2s linear all;
}
input[type="checkbox"]:checked:after {
  font-family: "Ionicons";
  content: "\f3ff";
  transition: .2s linear all;
}
input[type="radio"]:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 4px;
  font-family: "Ionicons";
  font-size: 20px;
  content: "\f401";
  transition: .2s linear all;
}
input[type="radio"]:checked:after {
  font-family: "Ionicons";
  content: "\f400";
  transition: .2s linear all;
}
label,
.password-reset {
  text-transform: uppercase;
  font-family: 'HelveticaNeueW01-65Medi';
  font-size: 12px;
}
button {
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-bottom: 1px solid white;
  color: #333;
  font-family: 'HelveticaNeueW01-57Cn';
  text-transform: uppercase;
  background: white;
  padding: 15px 0;
  transition: .2s linear all;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button i {
  display: inline-block;
  padding-left: 10px;
  font-size: 30px;
  transition: .2s linear all;
}
button:hover {
  border-bottom: 1px solid #333;
}
button:hover i {
  padding-left: 15px;
}
a.password-reset {
  margin-left: 30px;
}
.has-error {
  position: relative;
}
.has-error input,
.has-error input:focus {
  border: 1px solid #ee3124;
}
.has-error input {
  border: 1px solid #f58a83;
}
.has-error input:focus {
  border: 1px solid #ee3124;
}
.help-block {
  display: block;
  position: absolute;
  left: 0;
  bottom: -42px;
  width: 100%;
  color: white;
  padding: 0px 3px;
  font-size: 12px;
  color: #ee3124;
  margin-bottom: 20px;
}
textarea {
  width: 100%;
  height: 200px;
}
label span {
  color: #ddd;
}
.jelly {
  -webkit-animation: animation 1000ms linear infinite both;
  animation: animation 1000ms linear infinite both;
}
@-webkit-keyframes jelly-keyframes {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  3.4% {
    -webkit-transform: matrix3d(1.316, 0, 0, 0, 0, 1.407, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.316, 0, 0, 0, 0, 1.407, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    -webkit-transform: matrix3d(1.45, 0, 0, 0, 0, 1.599, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.45, 0, 0, 0, 0, 1.599, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  6.81% {
    -webkit-transform: matrix3d(1.659, 0, 0, 0, 0, 1.893, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.659, 0, 0, 0, 0, 1.893, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    -webkit-transform: matrix3d(1.883, 0, 0, 0, 0, 2.168, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.883, 0, 0, 0, 0, 2.168, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  10.21% {
    -webkit-transform: matrix3d(1.942, 0, 0, 0, 0, 2.226, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.942, 0, 0, 0, 0, 2.226, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.61% {
    -webkit-transform: matrix3d(2.123, 0, 0, 0, 0, 2.332, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.123, 0, 0, 0, 0, 2.332, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    -webkit-transform: matrix3d(2.141, 0, 0, 0, 0, 2.331, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.141, 0, 0, 0, 0, 2.331, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.52% {
    -webkit-transform: matrix3d(2.208, 0, 0, 0, 0, 2.239, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.208, 0, 0, 0, 0, 2.239, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    -webkit-transform: matrix3d(2.212, 0, 0, 0, 0, 2.187, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.212, 0, 0, 0, 0, 2.187, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.32% {
    -webkit-transform: matrix3d(2.196, 0, 0, 0, 0, 2.069, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.196, 0, 0, 0, 0, 2.069, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    -webkit-transform: matrix3d(2.151, 0, 0, 0, 0, 1.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.151, 0, 0, 0, 0, 1.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  25.23% {
    -webkit-transform: matrix3d(2.134, 0, 0, 0, 0, 1.938, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.134, 0, 0, 0, 0, 1.938, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.03% {
    -webkit-transform: matrix3d(2.063, 0, 0, 0, 0, 1.897, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.063, 0, 0, 0, 0, 1.897, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    -webkit-transform: matrix3d(2.048, 0, 0, 0, 0, 1.899, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.048, 0, 0, 0, 0, 1.899, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    -webkit-transform: matrix3d(1.979, 0, 0, 0, 0, 1.962, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.979, 0, 0, 0, 0, 1.962, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  36.74% {
    -webkit-transform: matrix3d(1.972, 0, 0, 0, 0, 1.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.972, 0, 0, 0, 0, 1.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    -webkit-transform: matrix3d(1.961, 0, 0, 0, 0, 2.022, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.961, 0, 0, 0, 0, 2.022, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  44.44% {
    -webkit-transform: matrix3d(1.966, 0, 0, 0, 0, 2.032, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.966, 0, 0, 0, 0, 2.032, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    -webkit-transform: matrix3d(1.991, 0, 0, 0, 0, 2.006, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.991, 0, 0, 0, 0, 2.006, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  59.86% {
    -webkit-transform: matrix3d(2.006, 0, 0, 0, 0, 1.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.006, 0, 0, 0, 0, 1.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    -webkit-transform: matrix3d(2.007, 0, 0, 0, 0, 1.992, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.007, 0, 0, 0, 0, 1.992, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  75.28% {
    -webkit-transform: matrix3d(2.001, 0, 0, 0, 0, 2.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.001, 0, 0, 0, 0, 2.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    -webkit-transform: matrix3d(1.999, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.999, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  90.69% {
    -webkit-transform: matrix3d(1.999, 0, 0, 0, 0, 1.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.999, 0, 0, 0, 0, 1.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(2, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@keyframes jelly-keyframes {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  3.4% {
    -webkit-transform: matrix3d(1.316, 0, 0, 0, 0, 1.407, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.316, 0, 0, 0, 0, 1.407, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    -webkit-transform: matrix3d(1.45, 0, 0, 0, 0, 1.599, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.45, 0, 0, 0, 0, 1.599, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  6.81% {
    -webkit-transform: matrix3d(1.659, 0, 0, 0, 0, 1.893, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.659, 0, 0, 0, 0, 1.893, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    -webkit-transform: matrix3d(1.883, 0, 0, 0, 0, 2.168, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.883, 0, 0, 0, 0, 2.168, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  10.21% {
    -webkit-transform: matrix3d(1.942, 0, 0, 0, 0, 2.226, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.942, 0, 0, 0, 0, 2.226, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.61% {
    -webkit-transform: matrix3d(2.123, 0, 0, 0, 0, 2.332, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.123, 0, 0, 0, 0, 2.332, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    -webkit-transform: matrix3d(2.141, 0, 0, 0, 0, 2.331, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.141, 0, 0, 0, 0, 2.331, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.52% {
    -webkit-transform: matrix3d(2.208, 0, 0, 0, 0, 2.239, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.208, 0, 0, 0, 0, 2.239, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    -webkit-transform: matrix3d(2.212, 0, 0, 0, 0, 2.187, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.212, 0, 0, 0, 0, 2.187, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.32% {
    -webkit-transform: matrix3d(2.196, 0, 0, 0, 0, 2.069, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.196, 0, 0, 0, 0, 2.069, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    -webkit-transform: matrix3d(2.151, 0, 0, 0, 0, 1.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.151, 0, 0, 0, 0, 1.96, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  25.23% {
    -webkit-transform: matrix3d(2.134, 0, 0, 0, 0, 1.938, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.134, 0, 0, 0, 0, 1.938, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.03% {
    -webkit-transform: matrix3d(2.063, 0, 0, 0, 0, 1.897, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.063, 0, 0, 0, 0, 1.897, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    -webkit-transform: matrix3d(2.048, 0, 0, 0, 0, 1.899, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.048, 0, 0, 0, 0, 1.899, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    -webkit-transform: matrix3d(1.979, 0, 0, 0, 0, 1.962, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.979, 0, 0, 0, 0, 1.962, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  36.74% {
    -webkit-transform: matrix3d(1.972, 0, 0, 0, 0, 1.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.972, 0, 0, 0, 0, 1.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    -webkit-transform: matrix3d(1.961, 0, 0, 0, 0, 2.022, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.961, 0, 0, 0, 0, 2.022, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  44.44% {
    -webkit-transform: matrix3d(1.966, 0, 0, 0, 0, 2.032, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.966, 0, 0, 0, 0, 2.032, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    -webkit-transform: matrix3d(1.991, 0, 0, 0, 0, 2.006, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.991, 0, 0, 0, 0, 2.006, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  59.86% {
    -webkit-transform: matrix3d(2.006, 0, 0, 0, 0, 1.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.006, 0, 0, 0, 0, 1.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    -webkit-transform: matrix3d(2.007, 0, 0, 0, 0, 1.992, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.007, 0, 0, 0, 0, 1.992, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  75.28% {
    -webkit-transform: matrix3d(2.001, 0, 0, 0, 0, 2.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2.001, 0, 0, 0, 0, 2.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    -webkit-transform: matrix3d(1.999, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.999, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  90.69% {
    -webkit-transform: matrix3d(1.999, 0, 0, 0, 0, 1.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.999, 0, 0, 0, 0, 1.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(2, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(2, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
.page-title-small {
  padding: 180px 0 60px 0;
  margin-bottom: 50px;
}
.page-title-small h1 {
  color: white;
}
footer a {
  color: #333 !important;
}
#blog-class {
  padding-top: 20px;
}
@media (min-width: 1224px) {
  .mobile-header,
  .no-desktop,
  .slideout,
  .mobile-nav-wrap {
    display: none;
  }
}
@media (max-width: 1224px) {
  header,
  .no-mobile {
    display: none !important;
  }
  body {
    margin-top: 190px;
  }
  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    height: 90px;
    z-index: 10;
  }
  .mobile-header .alert {
    margin-top: 90px;
    background-color: #f4756d;
    text-align: center;
    padding: 20px;
  }
  .mobile-header .alert .update-title {
    display: block;
  }
  .mobile-header .alert a {
    margin: 20px 20px 0px 20px;
  }
  .mobile-logo {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 90px;
    background: #ee3124;
    text-align: center;
    padding: 10px;
  }
  .mobile-logo a i {
    color: white;
    font-size: 30px;
  }
  .about .mobile-logo {
    background: #18a5dd;
  }
  .go .mobile-logo {
    background: #4a822b;
  }
  .do .mobile-logo {
    background: #0283b1;
  }
  .stay .mobile-logo {
    background: #25ada6;
  }
  .eat .mobile-logo {
    background: #2e358f;
  }
  .plan .mobile-logo {
    background: #e28c25;
  }
  .press .mobile-logo {
    background: #ee3124;
  }
  .mobile-call {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    bottom: 0;
    width: 90px;
  }
  .mobile-call a i {
    color: #333;
    font-size: 30px;
  }
  .mobile-nav-wrap,
  .secondary-mobile-nav-wrap {
    display: none;
    position: fixed;
    width: 100%;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    padding-left: 110px;
    z-index: 12;
  }
  .mobile-nav-wrap ul,
  .secondary-mobile-nav-wrap ul {
    padding-top: 30px;
  }
  .mobile-nav-wrap .primary-link,
  .secondary-mobile-nav-wrap .primary-link {
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 21px;
    font-family: 'HelveticaNeueW01-Thin';
  }
  .nav-side {
    position: absolute;
    background-color: #ee3124;
    width: 90px;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .about .mobile-logo,
  .about-nav-side,
  .slideout-about .nav-side {
    background: #18a5dd;
  }
  .go .mobile-logo,
  .go-nav-side,
  .slideout-go .nav-side {
    background: #4a822b;
  }
  .do .mobile-logo,
  .do-nav-side,
  .slideout-do .nav-side {
    background: #0283b1;
  }
  .stay .mobile-logo,
  .stay-nav-side,
  .slideout-stay .nav-side {
    background: #25ada6;
  }
  .eat .mobile-logo,
  .eat-nav-side,
  .slideout-eat .nav-side {
    background: #2e358f;
  }
  .plan .mobile-logo,
  .plan-nav-side,
  .slideout-plan .nav-side {
    background: #e28c25;
  }
  .press .mobile-logo,
  .press-nav-side,
  .slideout-press .nav-side {
    background: #ee3124;
  }
  .slideout {
    shadow: none;
  }
  .slideout a {
    color: #333;
  }
  .subheader {
    top: 0;
    left: 90px;
    height: 90px;
    z-index: 11;
  }
  .subheader h2 {
    display: none;
  }
  .section-nav {
    width: 100%;
  }
  .section-nav p {
    text-align: right;
    margin-right: 20px;
  }
  .section-nav-dropdown a {
    overflow: scroll;
  }
  .drop-icon {
    background: rgba(0, 0, 0, 0.2);
  }
  .slider,
  .hero {
    height: 25vh;
  }
  .belt {
    padding: 20px;
  }
  .full-mobile {
    width: 100% !important;
    padding: 20px;
  }
  .listing-bar a {
    display: none;
  }
  .listing-bar a.trip-link,
  .listing-bar a.add-to-trip {
    display: block;
    width: 100%;
    line-height: auto;
  }
  .update .row {
    display: block;
    padding: 20px;
    text-align: center;
  }
  .update .row .update-title {
    display: block;
  }
  footer,
  footer ul,
  footer ul li,
  .footer-contact,
  .footer-contact p {
    text-align: center;
  }
  .box {
    width: 46% !important;
  }
  .sectionboxes .box .text {
    font-size: 19px;
    padding-left: 5px;
  }
  .page-box h2 {
    font-size: 22px;
  }
  .rowflex {
    display: block;
    padding: 20px;
  }
  .trip-wrapper {
    padding-top: 40px;
  }
  .trip-trigger {
    width: 40px;
  }
  .trip-trigger i {
    font-size: 30px;
  }
  .trip-topper {
    height: 40px;
    padding-left: 20px;
  }
  .trip-info {
    min-height: 500px;
  }
  .day-nav-wrap {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    background: white;
    width: 90px !important;
    z-index: 2;
  }
  .trip-wrap .four_up li {
    width: 98% !important;
    height: auto !important;
    padding-bottom: 40px;
    background: white;
  }
  .trip-wrap {
    position: absolute;
    min-height: 100%;
    left: 0;
    right: 0;
    top: 40px;
    background: #ddd;
    padding: 20px 20px 20px 110px;
  }
  .home-logo img {
    width: 60%;
    margin: auto;
  }
  .sponsors.tiles > li,
  .tiles > .tile {
    height: auto;
    width: 100%;
  }
  .sponsor img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
  .callbox {
    margin-bottom: 10px;
    padding-top: 20px;
  }
  .info-bar .details .align-center,
  .info-bar .details .align-right {
    text-align: left;
  }
  .callbox-wide span {
    display: block;
    margin-right: 55%;
  }
  .callbox-wide .callbox-wide-cta {
    right: -40px;
    white-space: nowrap;
  }
  .callbox-cta {
    top: 60px;
    height: 40px;
    width: 100%;
  }
  .lower-content {
    padding-left: 10px;
  }
  .state-listings {
    padding-left: 10px;
  }
}
@media (max-width: 1224px) and (max-width: 620px) {
  .callbox-cta {
    top: 90px;
  }
}
@media (max-width: 1224px) and (max-width: 370px) {
  .callbox-cta {
    top: 110px;
  }
}
.cta-header {
  text-transform: uppercase;
  font-family: HelveticaNeueW01-65Medi;
  font-size: 12px;
  color: #ee3124;
}
