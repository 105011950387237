.trip-link {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	top: 0;
	bottom: 0;
	color: white;
	background: @dblue;
	padding: 0 60px 0 10px;
	.cond;
	.caps;

	.icon-wrap {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		bottom: 0;
		right: 0;
		width: 50px;
		background: rgba(0, 0, 0, 0.2);
		margin-left: 20px;
		font-size: 30px;
	}

	li {
		display: block;
	}
}

.daylist {
	display: none;
	position: absolute;
	right: 0;
	top: 49px;
	background: @light;
	width: 146px;
	padding: 20px;
	z-index: 20;
}

.trip-wrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding-top: 80px;
	z-index: 1000;
}

.trip-topper {
	position: absolute;
	display: flex;
	align-items: center;
	top: 0;
	left: 0;
	right: 0;
	background: @dark;
	height: 80px;
	.shadow;

	span {
		font-size: 21px;
		color: white;
	}
}

.trip-trigger {
	display: flex;
	position: absolute;
	right:0;
	top: 0;
	bottom: 0;
	width: 80px;
	align-items: center;
	justify-content: center;
	background: @dblue;

	i {
		color: white;
		font-size: 50px;
	}
}

.plus-wrap, .icon-wrap i {
	.transition;
}

.plus-wrap.active, .icon-wrap.active i {
	-webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.trip-info {
	display: none;
	background: white;
	padding: 30px 0 60px 0;
}

.logout {
	color: @dblue;
	.transition;
}

.logout:hover {
	color: darken(@dblue, 20%);
}


.day-nav {
	
	text-align: left;
	padding-bottom: 30px;

	li {
		display: block;
		.cond;
		.caps;
		padding: 5px;
		color: @light;
		border-bottom: 1px solid white;
		.transition;
	}

	li.active {
		color: @dark;
	}
}

.day-wrap {
	display: none;
}

.day-wrap.active {
	display: block;
}

.trip-stop {
	display: block;
	position: relative;
	padding: 15px 40px 60px 15px;
	.main-font;
	font-size: 30px;
	line-height: 40px;
	height: 200px !important;
	overflow: hidden;
	border: 1px solid @dark;
	margin-bottom: 20px;
}

.trip-stop .delete {
	position: absolute;
	right: 15px;
	bottom: 15px;
	font-size: 40px;
	color: @dark;
	.transition;
}

.trip-stop .delete:hover {
	color: @red;
}
.trip-builder { padding-bottom:10vh;}

.trip-box {
	background: white;
	margin-bottom: 30px;
	padding: 10px;
	.transition;
}

.trip-box:hover {
	.shadow;
}

.secondary-header {
	background: @dark;
	text-align: center;
	padding: 40px;

	h1 { color: white; }
}

.about .secondary-header {background: @about;}
.go .secondary-header {background: @go;}
.do .secondary-header {background: @do;}
.stay .secondary-header {background: @stay;}
.eat .secondary-header {background: @eat;}
.plan .secondary-header {background: @plan;}

.about .trip-trigger {background: @about;}
.go .trip-trigger {background: @go;}
.do .trip-trigger {background: @do;}
.stay .trip-trigger {background: @stay;}
.eat .trip-trigger {background: @eat;}
.plan .trip-trigger {background: @plan;}