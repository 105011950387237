.eat .logo {
	background: @eat;
}

.eat .discover h4 {
	color: @eat;
	font-size: 28px;
	letter-spacing: 1px;
}

.eat .subheader {
	background: @eat;
}

.eat li.eat-nav a.primary-link {
	color: white;
	background: @eat;
}

.eat h1 {
	color: @eat;
}

.eat .box a {
	color: @eat;
	border: 1px solid @eat;
}

.eat .box a:after {
	color: @eat;
}