.plan .logo {
	background: @plan;
}

.plan .discover h4 {
	color: @plan;
	font-size: 28px;
	letter-spacing: 1px;
}

.plan .subheader {
	background: @plan;
}

.plan li.plan-nav a.primary-link {
	color: white;
	background: @plan;
}

.plan h1 {
	color: @plan;
}

.plan .box a {
	color: @plan;
	border: 1px solid @plan;
}

.plan .box a:after {
	color: @plan;
}